import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import RegistrationView from 'views/RegistrationView';
import auth from 'lib';
import { setAppBusy, setLayout, setRegistering } from 'store/actions';
import { register } from 'gateways/UserServiceGateway';

const RegistrationContainer = ({ history }) => {
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    dispatch(
      setLayout({
        header: null,
        aside: null,
      }),
    );
  }, []);

  const handleSubmit = ({ email, password }) => {
    dispatch(setRegistering(true));
    dispatch(setAppBusy(true));
    setValidationErrors({});
    auth.createUserWithEmailAndPassword(email, password)
      .then((userCredentials) => register(userCredentials.user))
      .then(() => history.replace('/'))
      .catch((err) => {
        switch (err.code) {
          case 'auth/invalid-email':
          case 'auth/email-already-in-use':
            setValidationErrors({ email: err.message });
            break;
          case 'auth/weak-password':
            setValidationErrors({ password: err.message });
            break;
          default:
            setValidationErrors({ other: err.message });
        }
      })
      .finally(() => {
        dispatch(setAppBusy(false));
        dispatch(setRegistering(false));
      });
  };

  return (
    <RegistrationView
      onSubmit={handleSubmit}
      onAlertClose={() => setValidationErrors({ ...validationErrors, other: null })}
      validationError={validationErrors}
    />
  );
};

RegistrationContainer.displayName = 'RegistrationContainer';

RegistrationContainer.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default RegistrationContainer;
