import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, TextField, FormControl, MenuItem,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import WorkerAttributesTable from 'components/wmc/WorkerAttributesTable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ViewWrapper, ViewPaper, ConfirmationDialog } from '@mwp/frontend-components';

const WorkerEditView = (
  {
    isNew, worker, availableApps, validationErrors, onCancel, onSubmit, onDelete,
  },
) => {
  const [value, setValue] = useState(worker);
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordRepeatErr, setPasswordRepeatErr] = useState('');
  const [selectedApps, setSelectedApps] = useState(worker.workflowApps.map((a) => a.id));
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password && password !== passwordRepeat) {
      setPasswordRepeatErr('Password doesn\'t match');
      return;
    }
    setPasswordRepeatErr(null);

    const newSelectedApps = availableApps.filter((a) => selectedApps.includes(a.id));
    const valueToSave = { ...value, workflowApps: newSelectedApps };
    if (password) {
      valueToSave.password = password;
    }
    onSubmit(valueToSave);
  };

  const handleAddAttribute = (selectedApp, varName, varValue) => {
    const attrName = `${selectedApp}.${varName}`;
    setValue({
      ...value,
      attributesMap: {
        ...value.attributesMap,
        [attrName]: varValue,
      },
    });
  };

  const handleRemoveAttribute = (key) => {
    const attributes = value.attributesMap;
    delete attributes[key];
    setValue({
      ...value,
      attributesMap: attributes,
    });
  };

  const setPasswordValue = (event) => setPassword(event.target.value);

  const changeWorkerFieldValue = (e) => {
    const elementValue = ['checkbox', 'radio'].includes(e.target.type)
      ? e.target.checked
      : e.target.value;

    setValue({
      ...value,
      [e.target.name]: elementValue,
    });
  };

  return (
    <ViewWrapper>
      <ViewPaper>
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            label="Name"
            placeholder="Full name"
            fullWidth
            margin="normal"
            value={value.name}
            onChange={changeWorkerFieldValue}
          />
          <TextField
            name="username"
            label="Username"
            placeholder="Username"
            fullWidth
            margin="normal"
            disabled={!isNew}
            value={value.username}
            onChange={changeWorkerFieldValue}
          />
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                name="suspended"
                checked={value.suspended}
                onChange={changeWorkerFieldValue}
                defaultChecked={false}
              />
            )}
            label="Suspended"
          />
          <Box display="flex" justifyContent="space-between">
            <Box flexGrow={1} mr={1}>
              <TextField
                error={validationErrors && validationErrors.password}
                helperText={validationErrors && validationErrors.password}
                mr={1}
                name="password"
                label="Password"
                placeholder="Password"
                type="password"
                auto-complete="off"
                fullWidth
                margin="normal"
                value={password}
                onChange={setPasswordValue}
              />
            </Box>
            <Box flexGrow={1} ml={1}>
              <TextField
                error={passwordRepeatErr}
                helperText={passwordRepeatErr}
                name="password_repeat"
                label="Repeat password"
                placeholder="Repeat password"
                type="password"
                auto-complete="off"
                fullWidth
                margin="normal"
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
              />
            </Box>
          </Box>
          <TextField
            name="msisdn"
            label="MSISDN"
            placeholder="Phone number (e.g. 447711229384)"
            fullWidth
            margin="normal"
            value={value.msisdn}
            onChange={changeWorkerFieldValue}
          />
          <FormControl
            fullWidth
            margin="normal"
          >
            <InputLabel htmlFor="workerWorkflowApps">Workflow apps</InputLabel>
            <Select
              multiple
              name="workflowApps"
              value={selectedApps}
              onChange={(e) => setSelectedApps(e.target.value)}
              input={<Input name="workerWorkflowApps" id="workerWorkflowApps" />}
            >
              {availableApps.map((app) => (
                <MenuItem
                  key={app.id}
                  value={app.id}
                >
                  {app.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <WorkerAttributesTable
            attributes={value.attributesMap}
            availableApps={availableApps}
            onAdd={handleAddAttribute}
            onRemove={handleRemoveAttribute}
          />
          <Box display="flex" pt={2}>
            <Box flexGrow={1}>
              {!isNew && (
                <Button
                  color="secondary"
                  onClick={() => setOpenDeleteConfirmDialog(true)}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" onClick={onCancel}>Cancel</Button>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                disabled={!value.name || !value.username}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </ViewPaper>
      <ConfirmationDialog
        dismissButtonLabel="Cancel"
        open={openDeleteConfirmDialog}
        title="Delete worker"
        content={(
          <>
            <p>
              Do you want to delete the worker&nbsp;
              <strong>{worker.name}</strong>
              ?
            </p>
            <p>This operation can not be undone</p>
          </>
        )}
        confirmButtonLabel="Delete"
        handleConfirm={onDelete}
        handleDismiss={() => setOpenDeleteConfirmDialog(false)}
      />
    </ViewWrapper>
  );
};

WorkerEditView.propTypes = {
  isNew: PropTypes.bool.isRequired,
  worker: PropTypes.shape({
    workflowApps: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    attributesMap: PropTypes.shape({}).isRequired,
    suspended: PropTypes.bool,
    permittedDevices: PropTypes.arrayOf(PropTypes.shape({})),
    trackableId: PropTypes.number.isRequired,
    name: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  availableApps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  validationErrors: PropTypes.shape({
    password: PropTypes.string,
  }).isRequired,
};

WorkerEditView.defaultProps = {
  worker: {
    trackableId: 0,
    workflowApps: [],
    attributesMap: {},
    suspended: false,
    permittedDevices: [],
  },
};

export default WorkerEditView;
