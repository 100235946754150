export default {
  user: (state = null, action) => {
    switch (action.type) {
      case 'setUser':
        return action.payload;
      case 'logout':
        return null;
      default:
        return state;
    }
  },
  groups: (state = null, action) => {
    switch (action.type) {
      case 'setGroups':
        return action.payload;
      case 'logout':
        return null;
      default:
        return state;
    }
  },
  userCredentials: (state = null, action) => {
    switch (action.type) {
      case 'setUserCredentials':
        return action.payload;
      case 'logout':
        return null;
      default:
        return state;
    }
  },
  authError: (state = null, action) => {
    switch (action.type) {
      case 'setAuthError':
        return action.payload;
      default:
        return state;
    }
  },
  authInitialized: (state = false, action) => action.type === 'setAuthInitialized' || state,
  registering: (state = false, action) => (action.type === 'setRegistering' ? action.payload : state),
};
