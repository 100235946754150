export default {
  layout: (state = {}, action) => {
    switch (action.type) {
      case 'setLayout':
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
      case 'toggleAside':
        return {
          ...state,
          showAside:
              action.payload === undefined ? !state.showAside : action.payload,
        };
    }
  },
};
