import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import MarkerIcon from '@material-ui/icons/Room';
import MessageIcon from '@material-ui/icons/QuestionAnswer';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { ScrollableBox } from '@mwp/frontend-components';

const WorkerDetailsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;

  .workflowApps {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: ${({ theme }) => theme.spacing(1)}px;
    margin-right:0;

    &.overflow {
      border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
    }

    li {
      margin: 0 ${({ theme }) => theme.spacing(1)}px
        ${({ theme }) => theme.spacing(1)}px 0;
    }
  }
  .workerDetailsFooter {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .buttonsBar {
    text-align: right;
    white-space: nowrap;
  }
`;

const WorkerDetails = ({
  worker, className, canEdit, onMsisdnClick,
}) => (
  <WorkerDetailsBox p={2} className={className}>
    <Box>
      <Typography variant="h5">{worker.name}</Typography>
    </Box>
    {/* <Typography variant="h6">{worker.username}</Typography> */}

    <ScrollableBox as="ul" className="workflowApps" direction="vertical">
      {worker.workflowApps.map((wa) => (
        <li key={wa.name}>
          <Chip variant="outlined" size="small" label={wa.name} />
        </li>
      ))}
    </ScrollableBox>

    <Box className="workerDetailsFooter">
      <div>
        {worker.msisdn && (
          <Button onClick={onMsisdnClick}>
            <Typography variant="body2">{worker.msisdn}</Typography>
          </Button>
        )}
      </div>

      <Box className="buttonsBar">
        {canEdit && (
          <Link component={RouterLink} to={`/workers/${worker.trackableId}/edit`}>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Link>
        )}
        <Link
          component={RouterLink}
          to={`/workers/locations?ids=${worker.trackableId}`}
        >
          <IconButton>
            <MarkerIcon />
          </IconButton>
        </Link>
        <Link
          component={RouterLink}
          to={`/messages?ids=${worker.trackableId}`}
        >
          <IconButton>
            <MessageIcon />
          </IconButton>
        </Link>
      </Box>
    </Box>
  </WorkerDetailsBox>
);
WorkerDetails.propTypes = {
  worker: PropTypes.shape({
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    trackableId: PropTypes.number.isRequired,
    msisdn: PropTypes.string,
    workflowApps: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  className: PropTypes.string,
  canEdit: PropTypes.bool,
  onMsisdnClick: PropTypes.func,
};

WorkerDetails.defaultProps = {
  className: '',
  canEdit: false,
  onMsisdnClick: null,
};

export default WorkerDetails;
