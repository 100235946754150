import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEvents, createEvent, updateEvent, deleteEvent,
} from 'gateways/SchedulerServiceGateway';
import { Spinner, Header } from '@mwp/frontend-components';
import CalendarView from 'views/CalendarView';
import NavigationMenuContainer from './NavigationMenuContainer';
import ToolbarContainer from './ToolbarContainer';
import { addMessage, setLayout } from '../store/actions';

const CalendarContainer = () => {
  const dispatch = useDispatch();
  const userCredentials = useSelector((state) => state.userCredentials);

  const [events, setEvents] = useState();

  const refreshEvents = () => {
    fetchEvents(userCredentials)
      .then((res) => setEvents(res))
      .catch(() => {
        dispatch(addMessage({
          text: 'Could not fetch the events',
          severity: 'error',
          autoHideDuration: 6000,
        }));
        setEvents([]);
      });
  };

  useEffect(() => {
    refreshEvents();
  }, [setEvents]);

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer buttons={[]} />} />,
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  const onEventSave = (event) => {
    if (event.id) {
      updateEvent(userCredentials, event)
        .then(() => {
          dispatch(addMessage({
            text: 'Event updated',
            severity: 'success',
            autoHideDuration: 6000,
          }));
          refreshEvents();
        })
        .catch(() => {
          dispatch(addMessage({
            text: 'Could not update the event',
            severity: 'error',
            autoHideDuration: 6000,
          }));
        });
    } else {
      createEvent(userCredentials, event)
        .then(() => {
          refreshEvents();
        })
        .catch(() => {
          dispatch(addMessage({
            text: 'Could not create the event',
            severity: 'error',
            autoHideDuration: 6000,
          }));
        });
    }
  };

  const onEventDelete = (eventId) => {
    deleteEvent(userCredentials, eventId)
      .then(() => {
        dispatch(addMessage({
          text: 'Event deleted',
          severity: 'success',
          autoHideDuration: 6000,
        }));
        refreshEvents();
      })
      .catch(() => {
        dispatch(addMessage({
          text: 'Could not delete the event',
          severity: 'error',
          autoHideDuration: 6000,
        }));
      });
  };

  return (
    <>
      {!events && <Spinner relativeToParent />}
      {!!events && (
      <CalendarView
        events={events || []}
        onEventSave={onEventSave}
        onEventDelete={onEventDelete}
      />
      )}
    </>
  );
};

export default CalendarContainer;

CalendarContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
