import { getUsers as fetchApi } from 'gateways/UserServiceGateway';
import auth from 'lib';

const setUsers = (payload) => ({
  type: 'setUsers',
  payload,
});

// eslint-disable-next-line import/prefer-default-export
export const fetchUsers = () => (dispatch) => fetchApi(auth.currentUser).then((data) => {
  dispatch(setUsers(data));
});
