/* eslint-disable */
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { listWorkers, listWorkersAsMap, queryLocations } from 'gateways';
import { useDispatch, useSelector } from 'react-redux';
import { setLayout } from '../store/actions';
import { Header, Spinner } from '@mwp/frontend-components';
import ToolbarContainer from './ToolbarContainer';
import IconButton from '@material-ui/core/IconButton';
import FilterList from '@material-ui/icons/FilterList'
import NavigationMenuContainer from './NavigationMenuContainer';
import WorkerLocationsView from '../views/WorkerLocationsView';
import WorkersLocationsFilterView from 'views/WorkersLocationsFilterView';

function buildFilter(location) {
  const search = queryString.parse(location.search);
  return {
    from: (search.from && new Date(parseInt(search.from,10))),
    to: (search.to && new Date(parseInt(search.to,10))),
    ids: search.ids.split(',').map(id=>parseInt(id,10)),
    limit: (search.limit && parseInt(search.limit, 10)) || 10,
  };
}

function mapLocations(locations, workers) {
  return locations && locations.map(res => ({
    id: res.locationId,
    trackableId: res.trackableId,
    name: (workers && workers[res.trackableId] && workers[res.trackableId].name) || 'Unknown',
    position: {
      lng: res.qualifiedCoordinates.longitude,
      lat: res.qualifiedCoordinates.latitude,
    },
    timestamp: new Date(res.timestamp),
  }));
}

const WorkerLocationsContainer = ({ location, history }) => {

  const dispatch = useDispatch();
  const userCredentials = useSelector((state) => state.userCredentials);
  const [workers, setWorkers] = useState();
  const [locations, setLocations] = useState();
  const [displayFilter, setDisplayFilter] = useState(false);
  const displayFilterButton = (
    <IconButton
      color="inherit"
      key="setFiltersButton"
      onClick={() => setDisplayFilter(true)}
    >
      <FilterList />
    </IconButton>
  );

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer buttons={[displayFilterButton]} />} />,
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const filters = buildFilter(location);
    Promise.all(
      [
        queryLocations(userCredentials, filters.ids, filters.from, filters.to, filters.limit),
        listWorkersAsMap(userCredentials)
      ])
      .then(([locationsResult, workersResult]) => {
          setLocations(locationsResult);
          setWorkers(workersResult);
        }
      );
  }, [location]);

  const handleFilterChange = (newFilter) => {
    history.push({ pathname: location.pathname, search: queryString.stringify({
      ...newFilter,
      to: newFilter.to && newFilter.to.getTime(),
      from: newFilter.from && newFilter.from.getTime(),
    },{arrayFormat:'comma'}) });
    closeFilterForm()
  };

  const handleLocationSelected = (location) => {
    // nothing to do here right now
  };

  function closeFilterForm(){
    setDisplayFilter(false)
  }

  const filters=buildFilter(location)
  console.log("filters", filters);
  return (
    <>
      {!locations || !workers && <Spinner />}
      {locations && workers && (
        <>
        <WorkersLocationsFilterView onSubmit={handleFilterChange} onDismiss={closeFilterForm} workers={Object.values(workers)} filters={filters} open={displayFilter} />
        <WorkerLocationsView
          onLocationSelected={handleLocationSelected}
          onFilterChange={handleFilterChange}
          locations={mapLocations(locations, workers)}
          mapProps={{
            center: {
              lat: 51,
              lng: 0,
            },
            zoom: 6,
          }}
          filters={filters}
          onClearFilter={(filterName)=>{
            const newFilters = {...filters};
            delete newFilters[filterName];
            handleFilterChange(newFilters);
          }}
          workers={workers}
          />
        </>
      )}
    </>
  )
};

export default WorkerLocationsContainer;


