import auth from 'lib';
import { setAppBusy } from 'store/actions/system';
import { getSelf, register, getGroups } from 'gateways/UserServiceGateway';
import { batch } from 'react-redux';

export const setAuthInitialized = () => ({
  type: 'setAuthInitialized',
});

export const setUser = (payload) => ({
  type: 'setUser',
  payload,
});

export const setUserCredentials = (payload) => ({
  type: 'setUserCredentials',
  payload,
});

export const setAuthError = (payload) => ({
  type: 'setAuthError',
  payload,
});

export const setGroups = (payload) => ({
  type: 'setGroups',
  payload,
});

export const loadUser = (userCredentials, done) => (dispatch) => {
  dispatch(setAppBusy(true));
  getSelf(userCredentials)
    .then((user) => {
      if (!user) {
        return register(userCredentials);
      }
      return user;
    })
    .then((user) => getGroups(userCredentials).then((groups) => [user, groups]))
    .then(([user, groups]) => {
      // eslint-disable-next-line no-debugger
      const group = groups.find((grp) => grp.groupId === user.activeGroupId);
      batch(() => {
        dispatch(setUser({ ...user, group }));
        dispatch(setGroups(groups));
      });
    })
    .catch((userLoadError) => setAuthError(userLoadError))
    .finally(() => {
      dispatch(setAppBusy(false));
      done();
    });
};

export const login = ({ username, password }) => ((dispatch) => {
  dispatch(setAppBusy(true));
  auth.signInWithEmailAndPassword(username, password)
    .then(() => dispatch(setAuthError()))
    .catch((authError) => dispatch(setAuthError(authError)))
    .finally(() => dispatch(setAppBusy(false)));
});

export const logout = () => ((dispatch) => {
  dispatch(setAppBusy(true));
  auth.signOut()
    .then(() => dispatch({ type: 'logout' }))
    .finally(() => dispatch(setAppBusy(false)));
});

export const setRegistering = (registering) => ({
  type: 'setRegistering',
  payload: registering,
});
