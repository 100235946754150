import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as reactLib from 'react';
import * as styled from 'styled-components';
import * as materialUi from '@material-ui/core';
import * as muiPickers from '@material-ui/pickers';
import * as muiUtils from '@material-ui/core/utils';
import * as mwpFrontendComponents from '@mwp/frontend-components';
import * as reactRouterLib from 'react-router';
import * as reactRouterDomLib from 'react-router-dom';
import * as reactDomLib from 'react-dom';
import * as reactDomServer from 'react-dom/server';
import * as momentLib from 'moment';
import * as reactSwipableLib from 'react-swipeable';
import { addMessage, setAppBusy } from 'store/actions';

const loadPlugin = (url) => {
  const moduleScriptElement = document.createElement('script');
  const scriptUrl = `${url}?q=${Math.random()}`;
  moduleScriptElement.setAttribute('type', 'text/javascript');
  moduleScriptElement.setAttribute('src', scriptUrl);
  document.head.appendChild(moduleScriptElement);
  return scriptUrl;
};

const PluginContext = createContext({});
const pluginCallbacks = new Map();
const pluginConfigMap = new Map();

const PluginProvider = ({ children }) => {
  const [pluginsMap, setPluginsMap] = useState({});
  const [pluginsInitialised, setPluginsInitialised] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userCredentials = useSelector((state) => state.userCredentials);

  const wmcSdk = {
    registerPlugin: (plugin) => {
      setPluginsMap((currentPlugins) => ({ ...currentPlugins, [plugin.id]: plugin }));
      const pluginLoadedCb = pluginCallbacks.get(document.currentScript.src);
      pluginLoadedCb(true);
    },
    getUser: () => user,
    getUserCredentials: () => userCredentials,
    setAppBusy: (busy) => dispatch(setAppBusy(busy)),
    addMessage: (message) => dispatch(addMessage(message)),
    getPluginConfig: () => pluginConfigMap.get(document.currentScript.src),
  };

  useEffect(() => {
    if (user && user.group) {
      const allPluginsPromises = user
        .group
        .applicationReferences
        .filter((appReference) => appReference.plugin)
        .map((appReference) => {
          const scriptUrl = loadPlugin(appReference.url);
          pluginConfigMap.set(scriptUrl, appReference.config);
          return scriptUrl;
        })
        .map((scriptUrl) => new Promise((resolve) => {
          const timeout = setTimeout(() => resolve(false), 10000);
          pluginCallbacks.set(scriptUrl, () => {
            clearTimeout(timeout);
            resolve(true);
          });
        }));
      Promise.all(allPluginsPromises).then((allResults) => {
        if (allResults.some((result) => !result)) {
          dispatch(addMessage({ severity: 'error', text: 'Some plugins have failed to load' }));
        }
        setPluginsInitialised(true);
      });
    }
  }, [user, setPluginsInitialised]);

  const pluginDependencies = {
    react: reactLib,
    'react-dom': reactDomLib,
    'react-dom/server': reactDomServer,
    'react-router': reactRouterLib,
    'react-router-dom': reactRouterDomLib,
    'styled-components': styled,
    '@material-ui/core': materialUi,
    '@material-ui/core/utils': muiUtils,
    '@material-ui/pickers': muiPickers,
    '@mwp/frontend-components': mwpFrontendComponents,
    moment: momentLib,
    'react-swipeable': reactSwipableLib,
    '@mwp/wmc-sdk': wmcSdk,
  };

  window.define = (deps, callback) => {
    callback(...deps.map((name) => pluginDependencies[name]));
  };

  return (
    <PluginContext.Provider
      value={{
        plugins: Object.values(pluginsMap),
        pluginsInitialised,
      }}
    >
      {children}
    </PluginContext.Provider>
  );
};

PluginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const usePlugins = () => {
  const context = useContext(PluginContext);
  if (context === undefined) {
    throw new Error('usePlugins must be used within a PluginContext');
  }
  return context;
};

export {
  PluginProvider, usePlugins,
};
