import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavMenuLabelsToggle } from '@mwp/frontend-components';
import { toggleNavMenuLabels } from 'store/actions';

const NaveMenuLabelsToggleContainer = () => {
  const dispatch = useDispatch();
  const showNavMenuLabels = useSelector((state) => !state.navMenu.showLabels);

  function onClick() {
    dispatch(toggleNavMenuLabels());
  }

  return (
    <NavMenuLabelsToggle
      onClick={onClick}
      showNavMenuLabels={showNavMenuLabels}
    />
  );
};

export default NaveMenuLabelsToggleContainer;
