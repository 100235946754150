import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ViewWrapper } from '@mwp/frontend-components';
import Conversation from 'components/wmc/Conversation';

const StyledMessagesView = styled(ViewWrapper)`
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: 0;
  height: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(2)}px;
    margin: 0;
  }
`;

const StyledConverstation = styled(Conversation)`
  max-width: 600px;
`;

const MessagesView = ({ messages }) => (
  <StyledMessagesView>
    <StyledConverstation messages={messages} />
  </StyledMessagesView>
);

MessagesView.displayName = 'MessagesView';

MessagesView.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      mobileOriginated: PropTypes.bool.isRequired,
      workerName: PropTypes.string,
      text: PropTypes.string.isRequired,
      sentTime: PropTypes.string.isRequired,
      readTime: PropTypes.string,
    }),
  ).isRequired,
};

export default MessagesView;
