/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import IconButton from '@material-ui/core/IconButton';
import FilterList from '@material-ui/icons/FilterList';
import * as moment from 'moment';
import { setLayout } from '../store/actions';
import { Header, Spinner } from '@mwp/frontend-components';
import ToolbarContainer from './ToolbarContainer';
import NavigationMenuContainer from './NavigationMenuContainer';
import { listMessages, listWorkersAsMap } from '../gateways';
import MessagesView from '../views/MessagesView';
import MessagesFilterView from '../views/MessagesFilterView';

function buildFilter(location) {
  const search = queryString.parse(location.search);
  return {
    from: (search.from && new Date(parseInt(search.from, 10))),
    to: (search.to && new Date(parseInt(search.to, 10))),
    ids: (search.ids && search.ids.split(',').map((id) => parseInt(id, 10))) || [],
    limit: (search.limit && search.limit !== '0' && parseInt(search.limit, 10)) || undefined,
  };
}

function mapMessages(messages, workers) {
  return messages.map((message) => ({
    id: message.messagePayload.id,
    workerName: (workers[message.trackableId] && workers[message.trackableId].name)
      || <i>unknown</i>,
    text: message.messagePayload.message,
    sentTime: moment(message.messagePayload.creationTime).format(),
    readTime: message.readTimestamp && moment(message.readTimestamp).format('YYYY/MM/DD HH:mm:ss'),
    mobileOriginated: message.mobileOriginated,
  })).reverse();
}

function filter(messages, filters) {
  return messages
    .filter((message) => !filters.ids || !filters.ids.length || filters.ids.includes(message.trackableId))
    .filter((message) => !filters.from || moment(message.messagePayload.creationTime).isAfter(moment(filters.from)))
    .filter((message) => !filters.to || moment(message.messagePayload.creationTime).isBefore(moment(filters.to)))
    .slice(0, filters.limit);
}

const MessagesContainer = ({ location, history }) => {
  const dispatch = useDispatch();
  const userCredentials = useSelector((state) => state.userCredentials);
  const [workers, setWorkers] = useState();
  const [messages, setMessages] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(false);

  const displayFilterButton = (
    <IconButton
      color="inherit"
      key="setFiltersButton"
      onClick={() => setDisplayFilter(true)}
    >
      <FilterList />
    </IconButton>
  );

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer buttons={[displayFilterButton]} />} />,
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    Promise.all([
      listMessages(userCredentials),
      listWorkersAsMap(userCredentials),
    ])
      .then(([messagesResult, workersResult]) => {
        setMessages(messagesResult);
        setWorkers(workersResult);
      });
  }, [location]);

  const handleFilterChange = (newFilter) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
          ...newFilter,
          to: newFilter.to && newFilter.to.getTime(),
          from: newFilter.from && newFilter.from.getTime(),
        },
        { arrayFormat: 'comma' }),
    });
    setDisplayFilter(false);
  };


  return (
    <>
      {(!messages || !workers) && <Spinner />}
      {messages && workers && (
        <>
          <MessagesFilterView
            onDismiss={() => setDisplayFilter(false)}
            onSubmit={handleFilterChange}
            filters={buildFilter(location)}
            open={displayFilter}
            workers={Object.values(workers)}
          />
          <MessagesView messages={mapMessages(filter(messages, buildFilter(location)), workers)} />
        </>
      )}
    </>
  );
};

MessagesContainer.displayName = 'MessagesContainer';

MessagesContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default MessagesContainer;
