import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Input } from '@material-ui/core';

const MessagesFilterView = ({
  open,
  filters,
  workers,
  onDismiss,
  onSubmit,
}) => {
  const [value, setValue] = useState({
    from: filters.from || null,
    to: filters.to || null,
    ids: filters.ids || [],
    limit: filters.limit || 0,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              ampm={false}
              clearable
              fullWidth
              value={value.from}
              name="from"
              label="From"
              onChange={(d) => setValue({ ...value, from: d })}
            />
            <DateTimePicker
              ampm={false}
              clearable
              fullWidth
              value={value.to}
              name="to"
              label="To"
              onChange={(d) => setValue({ ...value, to: d })}
            />
          </MuiPickersUtilsProvider>
          <FormControl fullWidth>
            <InputLabel htmlFor="workers">Workers</InputLabel>
            <Select
              name="workers"
              multiple
              onChange={(e) => setValue({
                ...value,
                ids: e.target.value,
              })}
              value={value.ids}
            >
              {workers.map((worker) => (
                <MenuItem key={worker.trackableId} value={worker.trackableId}>
                  {worker.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="limit">Limit</InputLabel>
            <Input
              type="number"
              value={value.limit}
              inputProps={{ min: 0, max: '1000', step: '10' }}
              onChange={(e) => setValue({
                ...value,
                limit: parseInt(e.target.value, 10),
              })}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onDismiss}>
            Cancel
          </Button>
          <Button type="submit">Filter</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

MessagesFilterView.propTypes = {
  open: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    limit: PropTypes.number,
  }).isRequired,
  workers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      trackableId: PropTypes.number,
    }),
  ).isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

MessagesFilterView.displayName = 'MessagesFilterView';

export default MessagesFilterView;
