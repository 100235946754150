import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWorkflowApp } from 'store/actions';

export default () => {
  const dispatch = useDispatch();

  const workflowApps = useSelector((state) => state.workflowApps);

  useEffect(() => {
    dispatch(
      fetchWorkflowApp(),
    );
  }, []);

  return workflowApps;
};
