import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BarChartWidget from 'components/wmc/BarChartWidget';
import { fetchLogs } from 'store/actions';
import moment from 'moment';

const EventsWidgetContainer = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const today = moment().startOf('day');
  const timeSpanInDays = 7;
  const timeSpanBegin = today.subtract(timeSpanInDays, 'days');

  const logs = useSelector((state) => state.logs)
    // filtering out logs older than "timeSpanBegin"
    .filter((l) => l.timestamp > timeSpanBegin.unix())
    .reduce((prev, curr) => {
      const key = moment.unix(curr.timestamp).startOf('day');
      // eslint-disable-next-line no-param-reassign
      prev[key] = prev[key] ? prev[key] + 1 : 1;
      return prev;
    }, {});

  let d = 1;
  const chartData = [];
  while (d < timeSpanInDays + 1) {
    const day = moment(timeSpanBegin).add(d, 'days');
    chartData.push({
      name: day.format('ddd'),
      value: logs[day] || 0,
    });
    d += 1;
  }

  useEffect(() => {
    dispatch(fetchLogs({
      groupId: user.activeGroupId,
      from: timeSpanBegin.unix(),
      eventType: 'WORKER_LOGIN_EVENT',
    }));
  }, []);

  return <BarChartWidget data={chartData} title={`Last ${timeSpanInDays} days logins`} placeholder="Sorry, no logins this week" />;
};

EventsWidgetContainer.displayName = 'EventsWidgetContainer';

export default EventsWidgetContainer;
