import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import auth from 'lib';
import {
  loadUser, setAuthInitialized, setUser, setUserCredentials,
} from 'store/actions/user';
import { CssBaseline } from '@material-ui/core';
import { HashRouter } from 'react-router-dom';
import MainLayoutContainer from 'containers/MainLayoutContainer';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'themes/Themes';
import { Spinner } from '@mwp/frontend-components';
import { LoadingView } from 'views';

const BaseApp = ({ children }) => {
  const dispatch = useDispatch();
  const appBusy = useSelector((state) => state.appBusyState.isBusy);
  const registering = useSelector((state) => state.registering);
  const userCredentials = useSelector((state) => state.userCredentials);

  useEffect(() => {
    if (!registering && userCredentials) {
      dispatch(loadUser(userCredentials, () => dispatch(setAuthInitialized())));
    }
  }, [userCredentials, registering]);

  const initUser = (userCreds) => {
    if (userCreds) {
      dispatch(setUserCredentials(userCreds));
    } else {
      dispatch(setUserCredentials(null));
      dispatch(setUser(null));
      dispatch(setAuthInitialized());
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged(initUser);
  }, [registering]);

  const authInitilized = useSelector((state) => state.authInitialized);

  const renderApp = () => (
    <>
      {appBusy && (<Spinner />)}
      <HashRouter>
        <ThemeProvider theme={defaultTheme}>
          <MainLayoutContainer>{children}</MainLayoutContainer>
        </ThemeProvider>
      </HashRouter>
    </>
  );

  return (
    <div className="app">
      <CssBaseline />
      { authInitilized ? renderApp() : <LoadingView />}
    </div>
  );
};

BaseApp.propTypes = {
  children: PropTypes.element.isRequired,
};

BaseApp.displayName = 'BaseApp';

export default BaseApp;
