import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import EventIcon from '@material-ui/icons/OfflineBoltOutlined';
import CalendarIcon from '@material-ui/icons/TodayOutlined';
import { useMediaQuery } from '@material-ui/core';
import { toggleNavMenuLabels } from 'store/actions';
import { NavigationMenu } from '@mwp/frontend-components';
import { registerConnectionKey } from 'gateways';
import { usePlugins } from 'context/PluginContext';

const defaultMainMenuItems = {
  items: [
    {
      label: 'Home',
      href: '/',
      icon: <HomeIcon />,
    },
    {
      label: 'Messages',
      href: '/messages',
      icon: <MailIcon />,
    },
    {
      label: 'Workers',
      href: '/workers',
      icon: <AccountBoxIcon />,
    },
    {
      label: 'Users',
      href: '/users',
      icon: <PeopleIcon />,
    },
    {
      label: 'Notifications',
      href: '/notifications',
      icon: <NotificationImportantIcon />,
    },
    {
      label: 'Events',
      href: '/Events',
      icon: <EventIcon />,
    },
    {
      label: 'Calendar',
      href: '/calendar',
      icon: <CalendarIcon />,
    },
  ],
};

const NavigationMenuContainer = ({ className }) => {
  const dispatch = useDispatch();
  const compact = useSelector((state) => !state.navMenu.showLabels);
  const user = useSelector((state) => state.user);
  const userCredentials = useSelector((state) => state.userCredentials);
  const theme = useTheme();
  const mqSm = useMediaQuery(theme.breakpoints.only('sm'));
  const { plugins } = usePlugins();

  const [menuItems, setMenuItems] = useState([defaultMainMenuItems]);

  React.useEffect(() => {
    dispatch(toggleNavMenuLabels(!mqSm));
  }, [mqSm]);

  useEffect(() => {
    if (!user || !user.group || !user.group.applicationReferences) {
      return;
    }

    const externalApps = {
      groupLabel: 'Apps',
      items: user.group.applicationReferences
        .filter((appRef) => !appRef.plugin)
        .map((appRef) => ({
          label: appRef.name,
          href: appRef.url,
          iconUrl: appRef.iconUrl,
          external: true,
        })),
    };

    const mainMenuItems = {
      ...defaultMainMenuItems,
      items: [
        ...defaultMainMenuItems.items,
        ...plugins
          .flatMap((plugin) => plugin.menus.map((m) => ({ ...m, id: plugin.id })))
          .map((pluginMenu) => (
            {
              label: pluginMenu.title,
              href: pluginMenu.path ? `/plugins/${pluginMenu.id}${pluginMenu.path}` : undefined,
              icon: pluginMenu.icon,
              submenus: pluginMenu.submenus && pluginMenu.submenus.map((item) => ({
                label: item.title,
                href: item.path ? `/plugins/${pluginMenu.id}${item.path}` : undefined,
              })),
            }
          )),
      ],
    };

    setMenuItems([
      mainMenuItems,
      externalApps,
    ]);
  }, [user, plugins, setMenuItems]);

  const classes = ['NavigationMenuContainer'];

  const handleExternalLinkClick = (url) => {
    registerConnectionKey(userCredentials)
      .then((connectionKey) => userCredentials.getIdToken().then((token) => [connectionKey, token]))
      .then(([connectionKey, token]) => {
        const externalUrl = queryString.stringifyUrl({
          url,
          query: {
            mobiletracker_connection_key: connectionKey,
            token,
          },
        });
        window.open(externalUrl);
      });
  };

  if (className) classes.push(className);

  return (
    <NavigationMenu
      className={classes.join(' ')}
      menuItems={menuItems}
      compact={compact}
      onExternalLinkClick={handleExternalLinkClick}
    />
  );
};

NavigationMenuContainer.displayName = 'NavigationMenuContainer';

NavigationMenuContainer.propTypes = {
  className: PropTypes.string,
};

NavigationMenuContainer.defaultProps = {
  className: null,
};

export default NavigationMenuContainer;
