import { SystemActions } from '../actions/system';

export default {
  appBusyState: (state = { isBusy: false }, action) => {
    switch (action.type) {
      case SystemActions.SET_APP_BUSY:
        return { isBusy: action.payload };
      default:
        return state;
    }
  },

  messages: (state = [], action) => {
    switch (action.type) {
      case SystemActions.ADD_MESSAGE:
        return [...state, action.payload];
      case SystemActions.CLEAR_ALL_MESSAGES:
        return [];
      case SystemActions.CLEAR_MESSAGE:
        return state.filter((error) => error !== action.payload);
      default:
        return state;
    }
  },
};
