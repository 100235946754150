import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import { listMessages, listWorkersAsMap } from '../../gateways';
import MessagesWidget from '../../components/wmc/MessagesWidget';

function mapMessages(messages, workers) {
  return messages.map((message) => ({
    id: message.messagePayload.id,
    workerName: (workers[message.trackableId] && workers[message.trackableId].name)
      || <i>unknown</i>,
    text: message.messagePayload.message,
    sentTime: moment(message.messagePayload.creationTime).format(),
    readTime: message.readTimestamp && moment(message.readTimestamp).format('YYYY/MM/DD HH:mm:ss'),
    mobileOriginated: message.mobileOriginated,
  })).reverse();
}

const MessagesWidgetContainer = () => {
  const [messages, setMessages] = useState([]);
  const [workers, setWorkers] = useState({});
  const userCredentials = useSelector((state) => state.userCredentials);

  useEffect(() => {
    listMessages(userCredentials)
      .then(setMessages);
    listWorkersAsMap(userCredentials)
      .then(setWorkers);
  }, []);

  return <MessagesWidget messages={mapMessages(messages, workers)} />;
};

MessagesWidgetContainer.displayName = 'MessagesWidgetContainer';

export default MessagesWidgetContainer;
