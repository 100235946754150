import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  TextField, Paper, Box, Container,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import UserRoles from 'components/user/UserRoles';

const StyledUserEditView = styled(Container)`
  padding: ${({ theme }) => theme.spacing(2)}px;

  .buttonsBar {
    display: flex;
    justify-content: flex-end;
    button {
      margin: 0 ${({ theme }) => theme.spacing(1)}px;
    }

    .deleteBtn {
      order: -1;
      margin-right: auto;
    }

    .saveBtn {
      order: 1;
    }
  }
`;

const UserEditView = ({
  username,
  roles,
  onSubmit,
  onCancel,
  onDelete,
  readOnly,
}) => {
  const [userRoles, setUserRoles] = React.useState(roles);

  return (
    <StyledUserEditView>
      <Paper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(username, userRoles);
          }}
        >
          <Box p={2}>
            <TextField
              name="username"
              label="Email address"
              placeholder="Email address"
              fullWidth
              margin="normal"
              disabled
              value={username}
            />
          </Box>
          <UserRoles
            roles={userRoles}
            onChange={setUserRoles}
            readOnly={readOnly}
          />
          <Box className="buttonsBar" px={1} py={2}>
            <Button
              className="saveBtn"
              type="submit"
              color="primary"
              variant="outlined"
            >
              Save
            </Button>
            <Button
              className="cancelBtn"
              type="button"
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className="deleteBtn"
              type="button"
              color="secondary"
              onClick={onDelete}
            >
              Remove
            </Button>
          </Box>
        </form>
      </Paper>
    </StyledUserEditView>
  );
};

UserEditView.propTypes = {
  username: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

UserEditView.defaultProps = {
  username: '',
  roles: [],
  readOnly: false,
};

UserEditView.displayName = 'UserEditView';

export default UserEditView;
