import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

const SendSMSDialog = ({
  open, onClose, onSend, textError,
}) => {
  const [text, setText] = useState('');

  const handleSend = (e) => {
    e.preventDefault();
    onSend(text);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogTitle>
        Send SMS
      </DialogTitle>
      <form onSubmit={handleSend}>
        <DialogContent dividers>
          <TextField
            name="text"
            placeholder="Text message"
            label="Message"
            multiline
            rows={3}
            value={text}
            onChange={(e) => setText(e.target.value)}
            error={!!textError}
            helperText={textError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={!text}
            autoFocus
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SendSMSDialog.displayName = 'SendSMSDialog';

SendSMSDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  textError: PropTypes.string,
};

SendSMSDialog.defaultProps = {
  textError: '',
};

export default SendSMSDialog;
