import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { ViewWrapper, ViewPaper } from '@mwp/frontend-components';

const OnboardView = ({ hasGroups }) => (
  <ViewWrapper>
    <ViewPaper>
      <Typography variant="h3">Well done!</Typography>
      <p><Typography variant="body">You are now registered on the platform.</Typography></p>
      {!hasGroups && <p><Typography variant="body">Please ask your manager to complete setting up your account.</Typography></p>}
      {hasGroups && <p><Typography variant="body">Please select a group from the menu bar.</Typography></p> }
    </ViewPaper>
  </ViewWrapper>
);

export default OnboardView;

OnboardView.propTypes = {
  hasGroups: PropTypes.bool,
};

OnboardView.defaultProps = {
  hasGroups: false,
};
