import PropTypes from 'prop-types';
import React from 'react';

const PluginsContextProvider = ({ plugins, children }) => {
  const pluginsCopy = [...plugins];
  const plugin = pluginsCopy.pop();
  if (!plugin) {
    return children;
  }

  const { provider: Provider } = plugin;

  return (Provider) ? (
    <Provider key={Math.random()}>
      <PluginsContextProvider plugins={pluginsCopy}>
        {children}
      </PluginsContextProvider>
    </Provider>
  ) : (
    <PluginsContextProvider plugins={pluginsCopy}>
      {children}
    </PluginsContextProvider>
  );
};

PluginsContextProvider.propTypes = {
  plugins: PropTypes.arrayOf(PropTypes.shape({ provider: PropTypes.func })).isRequired,
  children: PropTypes.node.isRequired,
};

export default PluginsContextProvider;
