import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const ChangePasswordView = (
  {
    open, onSubmit, onDismiss, errors,
  },
) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordRepeatError, setPasswordRepeatError] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== passwordRepeat) {
      setPasswordRepeatError('Password does not match');
      return;
    }

    setPasswordRepeatError('');
    onSubmit({
      currentPassword,
      newPassword,
    });
  };

  useEffect(() => {
    setCurrentPassword('');
    setPasswordRepeat('');
    setNewPassword('');
    setPasswordRepeatError('');
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onDismiss}
      >
        <DialogTitle>Change password</DialogTitle>
        <form>
          <DialogContent>
            <TextField
              fullWidth
              type="password"
              name="currentPassword"
              label="Current password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              error={errors && errors.password}
              helperText={errors && errors.password}
            />
            <TextField
              fullWidth
              type="password"
              name="newPassword"
              label="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={errors && errors.newPassword}
              helperText={errors && errors.newPassword}
            />
            <TextField
              fullWidth
              type="password"
              name="passwordRepeat"
              label="Repeat password"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
              error={passwordRepeatError}
              helperText={passwordRepeatError}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              onClick={onDismiss}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!currentPassword || !newPassword || !passwordRepeat}
              onClick={handleFormSubmit}
            >
              Change
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={errors && errors.other}
      >
        <Alert
          severity="error"
          elevation={6}
          variant="filled"
        >
          {errors && errors.other}
        </Alert>
      </Snackbar>
    </>
  );
};

ChangePasswordView.displayName = 'ChangePasswordView';

ChangePasswordView.propTypes = {
  open: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    password: PropTypes.string,
    newPassword: PropTypes.string,
    other: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

ChangePasswordView.defaultProps = {
  errors: {},
};

export default ChangePasswordView;
