import React from 'react';
import PropTypes from 'prop-types';
import ChartWidget from 'components/wmc/ChartWidget';
import { defaultTheme as theme } from 'themes';

import {
  BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid,
} from 'recharts';

const BarChartWidget = ({
  className, data, title, placeholder,
}) => {
  const classes = ['BarChartWidget'];
  if (className) classes.push(className);

  return (
    <ChartWidget title={title} placeholder={placeholder} className={classes.join(' ')}>
      {data.length > 0 && (
      <BarChart
        data={data}
        margin={{
          top: 50, right: 50, left: 10, bottom: 10,
        }}
      >
        <XAxis dataKey="name" stroke={theme.palette.secondary.dark} />
        <YAxis />
        <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#ccc' }} />

        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Bar dataKey="value" fill={theme.palette.secondary.light} />
      </BarChart>
      )}
    </ChartWidget>
  );
};

BarChartWidget.displayName = 'BarChartWidget';

BarChartWidget.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  title: PropTypes.string,
  placeholder: PropTypes.string,
};

BarChartWidget.defaultProps = {
  className: null,
  data: [],
  title: null,
  placeholder: undefined,
};

export default BarChartWidget;
