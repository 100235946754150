import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const StyledUserRoles = styled.div`
    padding: ${({ theme }) => theme.spacing(2)}px;
`;

const RolesList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  li {
    margin: 0 ${({ theme }) => theme.spacing()}px 0 0;
    padding: 0;
  }
`;

const UserRoles = ({ roles, readOnly, onChange }) => {
  const [newRoleValue, setNewRoleValue] = useState();

  const onAddRole = () => {
    onChange([...roles, newRoleValue]);
    setNewRoleValue('');
  };

  const handleDelete = (role) => {
    onChange(roles.filter((r) => r !== role));
  };

  return (
    <StyledUserRoles>
      <RolesList>{roles.map((role) => <li><Chip onDelete={!readOnly && (() => handleDelete(role))} size="small" label={role} /></li>)}</RolesList>

      {!readOnly
      && (
      <div>
        <TextField
          name="role"
          placeholder="Role"
          value={newRoleValue}
          onChange={(e) => setNewRoleValue(e.target.value)}
        />
        <Button onClick={onAddRole} disabled={!newRoleValue}>Add</Button>
      </div>
      )}
    </StyledUserRoles>
  );
};

UserRoles.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UserRoles;
