import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WidgetView } from '@mwp/frontend-components';
import { ResponsiveContainer } from 'recharts';

const Wrapper = styled(WidgetView)`
  .widgetViewContent {
    display: flex;
    flex-direction: column;
  }

  .chartWrapper{
    flex-grow:1;
  }
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100px;
  font-size:${({ theme }) => theme.typography.h6.fontSize};
  color:${({ theme }) => theme.palette.table.placeholderText};
`;

const ChartWidget = ({
  className, children, title, placeholder, footer,
}) => {
  const classes = ['ChartWidget'];
  if (className) classes.push(className);

  return (
    <Wrapper footer={footer} className={classes.join(' ')} title={title}>
      <div className="chartWrapper">
        {children ? (
          <ResponsiveContainer width="100%" height="100%" minHeight={300}>
            { children }
          </ResponsiveContainer>
        ) : (
          <Placeholder>
            {placeholder}
          </Placeholder>
        )}
      </div>
    </Wrapper>
  );
};

ChartWidget.displayName = 'ChartWidget';

ChartWidget.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
  ]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placeholder: PropTypes.string,
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.bool,
  ]),
};

ChartWidget.defaultProps = {
  className: null,
  children: null,
  title: null,
  footer: null,
  placeholder: 'Sorry, no data to display',
};

export default ChartWidget;
