import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;

  > header {
    flex-shrink: 0;
    position: relative;
    z-index: 3;
  }

  > main {
    display: flex;
    flex: 1 1 auto;

    min-height: 0px;

    > aside {
      flex-basis: auto;
      overflow-y: auto;
      flex-shrink:0;
    }

    > .mainContentWrapper {
      flex-basis: auto;
      flex-grow: 1;
      overflow-y: auto;
      position: relative;
    }
  }
`;

const MainLayout = ({
  header, children, aside, showAside, className, message,
}) => {
  const mainRef = React.useRef();
  React.useEffect(() => {
    mainRef.current.scrollTop = 0;
  });

  return (
    <Wrapper showAside={showAside} className={className || ''}>
      {header && <header>{header}</header>}
      <main>
        {aside && showAside && <aside>{aside}</aside>}
        <div className="mainContentWrapper" ref={mainRef}>{children}</div>
      </main>
      {message}
    </Wrapper>
  );
};

MainLayout.displayName = 'MainLayout';

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
  header: PropTypes.element,
  aside: PropTypes.element,
  showAside: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.element,
};

MainLayout.defaultProps = {
  header: null,
  aside: null,
  showAside: false,
  className: '',
  message: null,
};

export default MainLayout;
