import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '@mwp/frontend-components';
import ToolbarContainer from 'containers/ToolbarContainer';
import OnboardView from 'views/OnboardView';
import { setLayout } from 'store/actions';

const OnboardContainer = () => {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer />} />,
      }),
    );
  }, [dispatch]);

  return <OnboardView hasGroups={groups && !!groups.length} />;
};

export default OnboardContainer;
