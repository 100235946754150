import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const WorkerAttributesTable = ({
  attributes, availableApps, onAdd, onRemove,
}) => {
  const [selectedApp, setApp] = useState('');
  const [varName, setVarName] = useState('');
  const [varValue, setVarValue] = useState('');

  const lookupAppName = (appNameSpace) => {
    if (appNameSpace.startsWith('workermanager.app._system')) {
      return 'System';
    }

    if (appNameSpace.startsWith('workermanager.app')) {
      const appId = appNameSpace.substring('workermanager.app.'.length);
      const app = availableApps.find((a) => a.id === appId);
      return (app && app.name) || appNameSpace;
    }

    return appNameSpace;
  };

  const extractAppAndName = (attributeName) => {
    const lastDotIndex = attributeName.lastIndexOf('.');
    const appNameSpace = attributeName.substring(0, lastDotIndex);
    const appVarName = attributeName.substring(lastDotIndex + 1);
    return [lookupAppName(appNameSpace), appVarName];
  };

  const add = () => {
    onAdd(selectedApp, varName, varValue);
    setApp('');
    setVarName('');
    setVarValue('');
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">App</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Value</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          { Object.entries(attributes).map(([key, value]) => {
            const [appNameSpace, appVarName] = extractAppAndName(key);
            return (
              <TableRow key={key}>
                <TableCell>{ appNameSpace }</TableCell>
                <TableCell>{ appVarName }</TableCell>
                <TableCell>{ value }</TableCell>
                <TableCell><Button onClick={() => onRemove(key)} color="primary">Remove</Button></TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell>
              <FormControl fullWidth>
                <Select
                  value={selectedApp}
                  onChange={(event) => {
                    setApp(event.target.value);
                  }}
                  input={<Input name="app" id="app" />}
                >
                  { availableApps.map((app) => (
                    <MenuItem
                      key={`workermanager.app.${app.id}`}
                      value={`workermanager.app.${app.id}`}
                    >
                      {app.name}
                    </MenuItem>
                  ))}
                  <MenuItem key="workermanager.app._system" value="workermanager.app._system">System</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <TextField
                placeholder="name"
                value={varName}
                onChange={(event) => setVarName(event.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                placeholder="value"
                value={varValue}
                onChange={(event) => setVarValue(event.target.value)}
              />
            </TableCell>
            <TableCell>
              <Button
                color="primary"
                onClick={add}
                disabled={!selectedApp && !varName && !varValue}
              >
                Add
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

WorkerAttributesTable.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  availableApps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default WorkerAttributesTable;
