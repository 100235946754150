import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setAppBusy, setLayout } from 'store/actions';
import { Header, Spinner } from '@mwp/frontend-components';
import ToolbarContainer from 'containers/ToolbarContainer';
import NavigationMenuContainer from 'containers/NavigationMenuContainer';
import { useApps } from 'hooks';
import {
  deleteWorker, fetchWorker, saveWorker,
} from '../gateways';
import WorkerEditView from '../views/WorkerEditView';
import { addMessage } from '../store/actions/system';

const WorkerEditContainer = ({ match, history }) => {
  const dispatch = useDispatch();
  const userCredentials = useSelector((state) => state.userCredentials);
  const user = useSelector((state) => state.user);
  const [worker, setWorker] = useState();
  const availableApps = useApps();
  const [validationErrors, setValidationErrors] = useState({});
  const trackableId = match.params && match.params.trackableId
    ? parseInt(match.params.trackableId, 10) : 0;

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer />} />,
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (trackableId !== 0) {
      fetchWorker(userCredentials, trackableId)
        .then(setWorker);
    }
  }, [userCredentials, trackableId]);

  const handleSubmit = (workerUpdated) => {
    if (!workerUpdated.username) {
      setValidationErrors({
        username: 'Username can not be empty',
      });
      return;
    }
    setValidationErrors({});
    dispatch(setAppBusy(true));
    saveWorker(userCredentials, {
      ...worker,
      ...workerUpdated,
      msisdn: workerUpdated.msisdn || '',
      groupId: user.group.groupId,
    })
      .then(() => history.goBack())
      .catch(() => {
        dispatch(addMessage({
          text: 'Failed to save worker',
          severity: 'error',
          autoHideDuration: 6000,
        }));
      })
      .finally(() => dispatch(setAppBusy(false)));
  };

  const handleDelete = () => {
    deleteWorker(userCredentials, worker)
      .then(() => history.goBack())
      .catch((err) => setValidationErrors({ other: err.message }));
  };

  return (
    <>
      {((!worker && trackableId !== 0) || !availableApps) && <Spinner />}
      {(worker || trackableId === 0) && availableApps && (
        <WorkerEditView
          worker={worker}
          availableApps={availableApps}
          onCancel={() => history.goBack()}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          validationErrors={validationErrors}
          isNew={trackableId === 0}
        />
      )}
    </>
  );
};

WorkerEditContainer.displayName = 'WorkerEditContainer';

WorkerEditContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      trackableId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape(
    { goBack: PropTypes.func.isRequired },
  ).isRequired,
};

export default WorkerEditContainer;
