import { workermanagerUrl } from 'apiConfig';

export const listWorkflowApps = (userCredentials) => {
  const url = `${workermanagerUrl}/rest/workflowapps`;
  return userCredentials
    .getIdToken()
    .then((token) => fetch(url, { headers: { Authentication: `Bearer ${token}` } }))
    .then((res) => res.json());
};

export const listWorkers = (userCredentials) => {
  const url = `${workermanagerUrl}/rest/endusers/`;
  return userCredentials
    .getIdToken()
    .then((token) => fetch(url, { headers: { Authentication: `Bearer ${token}` } }))
    .then((res) => res.json());
};

export const listWorkersAsMap = (userCredentials) => listWorkers(userCredentials)
  .then((workers) => workers.reduce((workersObject, worker) => {
    // eslint-disable-next-line no-param-reassign
    workersObject[worker.trackableId] = worker;
    return workersObject;
  }, {}));

export const fetchSessions = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(`${workermanagerUrl}/rest/sessions/`, {
    headers: { Authentication: `Bearer ${token}` },
  }))
  .then((res) => res.json());

export const terminateSession = (userCredentials, sessionId) => userCredentials
  .getIdToken()
  .then((token) => fetch(`${workermanagerUrl}/rest/sessions/${sessionId}`, {
    method: 'DELETE',
    headers: { Authentication: `Bearer ${token}` },
  }))
  .then((res) => res.text());

export const fetchWorker = (userCredentials, trackableId) => {
  const url = `${workermanagerUrl}/rest/endusers/${trackableId}`;
  return userCredentials
    .getIdToken()
    .then((token) => fetch(url, { headers: { Authentication: `Bearer ${token}` } }))
    .then((res) => res.json());
};

export const deleteWorker = (userCredentials, worker) => {
  const url = `${workermanagerUrl}/rest/endusers/${worker.trackableId}`;
  return userCredentials.getIdToken().then((token) => fetch(url, {
    method: 'DELETE',
    headers: {
      Authentication: `Bearer ${token}`,
    },
  }));
};

export const saveWorker = (userCredentials, worker) => {
  const url = `${workermanagerUrl}/rest/endusers/${worker.trackableId || 0}`;
  return userCredentials.getIdToken()
    .then((token) => fetch(url, {
      method: 'POST',
      headers: {
        Authentication: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(worker),
    }))
    .then((res) => {
      if (res.status !== 200) throw new Error(res);
    });
};

export const sendSessionMessage = (userCredentials, sessionId, message) => {
  const url = `${workermanagerUrl}/json/app/message/send/${sessionId}`;
  return userCredentials.getIdToken().then((token) => fetch(url, {
    method: 'POST',
    headers: {
      Authentication: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  }));
};

export const listMessages = (userCredentials) => {
  const url = `${workermanagerUrl}/json/app/message/inbox/`;
  return userCredentials
    .getIdToken()
    .then((token) => fetch(url, {
      headers: { Authentication: `Bearer ${token}` },
    }))
    .then((res) => res.json());
};
