import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, { createRef, useEffect } from 'react';
import ArrowIcon from '@material-ui/icons/ArrowForwardOutlined';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import { ViewPaper } from '@mwp/frontend-components';

const StyledMessagesView = styled(ViewPaper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
  margin: auto;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  background: ${({ theme }) => theme.backgrounds.messages};
  background-attachment: scroll;
`;

const Message = styled(Paper)`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  max-width: 90%;
  margin-left: auto;
  min-width: 55%;

  .header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(0.3, 0.5)};

    .sentTime {
      margin-left: auto;
    }
    .arrowIcon {
      font-size:0.9em;
      color: ${({ theme }) => theme.palette.messages.worker};
    }
    .sender{
      font-family: monospace;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.messages.worker};
      margin:0 0.3em;
    }

  }


  &.mobileoriginated {
    margin-left: 0;
    margin-right: auto;
  }
`;

const MessageText = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 2)};
  ${({ read }) => !read && 'font-weight: 600;'}
`;

const MessageSentTime = styled.div`
  color: ${({ theme }) => theme.palette.secondary.dark};
  padding: 0 3px;
  font-size:0.7rem;
`;

const MessageReadTime = styled.div`
  font-size: smaller;
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-top: -${({ theme }) => theme.spacing(2)}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .readIcon {
    color: ${({ theme }) => theme.palette.grey[600]};
    font-size: 1.3em;
    margin-right:0.3em;
  }
`;

const DateSeparator = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.spacing(1)}px;
  text-align: center;
  color: white;

  span {
    background-color:${({ theme }) => theme.palette.grey[400]};
    padding: ${({ theme }) => theme.spacing(0.5, 2)};
    border-radius: 100px;
  }
`;

function renderMessages(messages) {
  const elements = [];
  if (messages.length === 0) {
    elements.push(
      <Alert severity="info">
        <AlertTitle>No messages</AlertTitle>
        Currently there are no messages to display
      </Alert>,
    );
  }
  let lastSeenDate = null;
  messages.forEach((message) => {
    const messageDate = message.sentTime.substring(0, 10);
    const messageTime = message.sentTime.substring(11, 19);
    if (lastSeenDate !== messageDate) {
      lastSeenDate = messageDate;
      elements.push(
        <DateSeparator key={messageDate}>
          <span>
            {messageDate}
          </span>
        </DateSeparator>,
      );
    }
    elements.push(
      <React.Fragment key={message.id}>
        <Message key={message.id} className={message.mobileOriginated && 'mobileoriginated'}>
          <div className="header">
            {!message.mobileOriginated && <ArrowIcon className="arrowIcon" />}
            <span className="sender">
              {message.workerName}
            </span>
            {message.mobileOriginated && <ArrowIcon className="arrowIcon" />}
            <MessageSentTime className="sentTime">{messageTime}</MessageSentTime>
          </div>

          <MessageText read={message.mobileOriginated || !!message.readTime}>
            {message.text}
          </MessageText>

        </Message>
        {message.readTime && (
          <MessageReadTime>
            <DoneIcon className="readIcon" />
            {message.readTime}
          </MessageReadTime>
        )}
      </React.Fragment>,
    );
  });
  return elements;
}

const Conversation = ({ messages, className }) => {
  const container = createRef();

  useEffect(() => {
    container.current.scrollTop = container.current.scrollHeight - container.current.clientHeight;
  }, [messages]);

  return (
    <StyledMessagesView className={className} ref={container}>
      {renderMessages(messages)}
    </StyledMessagesView>
  );
};

Conversation.displayName = 'MessagesView';

Conversation.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      mobileOriginated: PropTypes.bool.isRequired,
      workerName: PropTypes.string,
      text: PropTypes.string.isRequired,
      sentTime: PropTypes.string.isRequired,
      readTime: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
};

Conversation.defaultProps = {
  className: 'Conversation',
};

export default Conversation;
