import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWorkers } from 'store/actions';

export default () => {
  const dispatch = useDispatch();

  const workers = useSelector((state) => state.workers);

  useEffect(() => {
    dispatch(
      fetchWorkers(),
    );
  }, []);

  return workers;
};
