import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns:1fr;
  gap:${({ theme }) => theme.spacing(3)}px;
  padding:${({ theme }) => theme.spacing(1)}px;
  max-height: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    gap:${({ theme }) => theme.spacing(3)}px;
    padding:${({ theme }) => theme.spacing(2)}px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap:${({ theme }) => theme.spacing(4)}px;
    padding:${({ theme }) => theme.spacing(3)}px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    grid-template-columns:1fr 1fr;
    gap:${({ theme }) => theme.spacing(5)}px;
    padding:${({ theme }) => theme.spacing(4)}px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    grid-template-columns:1fr 1fr 1fr;
  }

  > * {
    &:last-child{
      margin-bottom:${({ theme }) => theme.spacing(1)}px;
    }
  }
`;

const WidgetsDisplay = ({ className, children }) => {
  const classes = ['WidgetsDisplay'];
  if (className) classes.push(className);

  return (
    <Wrapper className={classes.join(' ')}>
      {children}
    </Wrapper>
  );
};

WidgetsDisplay.displayName = 'WidgetsDisplay';

WidgetsDisplay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

WidgetsDisplay.defaultProps = {
  className: null,
};

export default WidgetsDisplay;
