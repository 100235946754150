const defaultFirebaseConfig = {
  apiKey: 'AIzaSyD1sMlROAdpazSDVEj55stkVf6sJ281nc8',
  authDomain: 'mwp-user-authentication.firebaseapp.com',
  databaseURL: 'https://mwp-user-authentication.firebaseio.com',
  projectId: 'mwp-user-authentication',
  storageBucket: 'mwp-user-authentication.appspot.com',
  messagingSenderId: '879565698342',
  appId: '1:879565698342:web:c1f1868efe16fda2',
};

const overrideConfig = (window.wmcConfig && window.wmcConfig.firebaseConfig) || {};

export default { ...defaultFirebaseConfig, ...overrideConfig };
