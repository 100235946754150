import React, { useEffect, useState } from 'react';
import auth from 'lib/auth';
import LoginView from 'views/LoginView';
import { setAppBusy, setLayout } from 'store/actions';
import { useDispatch } from 'react-redux';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState();

  useEffect(() => {
    dispatch(
      setLayout({
        header: null,
        aside: null,
      }),
    );
  }, []);

  const handleViewSubmit = () => {
    dispatch(setAppBusy(true));
    auth.signInWithEmailAndPassword(email, password).catch((err) => {
      dispatch(setAppBusy(false));

      const loginError = {};
      switch (err.code) {
        case 'auth/invalid-email':
          loginError.email = { message: err.message };
          break;

        case 'auth/wrong-password':
          loginError.password = { message: err.message };
          break;

        default:
          loginError.other = { message: err.message };
      }
      setValidationError(loginError);
    });
  };

  const handleAlertClose = () => {
    setValidationError({});
  };

  const handleFieldChanges = (fieldName, value) => {
    switch (fieldName) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
    }
  };

  return (
    <LoginView
      email={email}
      password={password}
      validationError={validationError}
      onSubmit={handleViewSubmit}
      onChange={handleFieldChanges}
      onAlertClose={handleAlertClose}
    />
  );
};

export default LoginContainer;
LoginContainer.displayName = 'LoginContainer';
