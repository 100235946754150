import React, { useState } from 'react';
import PropTypes from 'prop-types';
import auth from 'lib/auth';
import { useSelector } from 'react-redux';
import ChangePasswordView from 'views/ChangePasswordView';

const ChangePasswordContainer = ({ open, onDismiss }) => {
  const [errors, setErrors] = useState();
  const userCredentials = useSelector((state) => state.userCredentials);

  const changePassword = ({ currentPassword, newPassword }) => {
    const credentials = auth.EmailAuthProvider.credential(
      userCredentials.email,
      currentPassword,
    );
    userCredentials.reauthenticateWithCredential(credentials)
      .then(() => userCredentials.updatePassword(newPassword))
      .then(() => onDismiss())
      .catch((err) => {
        switch (err.code) {
          case 'auth/wrong-password':
            setErrors({ password: err.message });
            break;
          case 'auth/weak-password':
            setErrors({ newPassword: err.message });
            break;
          default:
            setErrors({ other: err.message });
        }
      });
  };

  const handleDismiss = () => {
    setErrors(null);
    onDismiss();
  };

  return (
    <ChangePasswordView
      onSubmit={changePassword}
      open={open}
      onDismiss={handleDismiss}
      errors={errors}
    />
  );
};

ChangePasswordContainer.displayName = 'ChangePasswordContainer';

ChangePasswordContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default ChangePasswordContainer;
