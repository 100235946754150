export const SystemActions = {
  SET_APP_BUSY: 'setAppBusy',
  ADD_MESSAGE: 'addMessage',
  CLEAR_ALL_MESSAGES: 'clearAllMessages',
  CLEAR_MESSAGE: 'clearMessage',
};

export const setAppBusy = (busy) => ({
  type: SystemActions.SET_APP_BUSY,
  payload: busy,
});

export const addMessage = (message) => ({
  type: SystemActions.ADD_MESSAGE,
  payload: message,
});

export const clearAllMessages = () => ({
  type: SystemActions.CLEAR_ALL_MESSAGES,
});

export const clearMessage = (message) => ({
  type: SystemActions.CLEAR_MESSAGE,
  payload: message,
});
