export default {
  events: (state = [], action) => {
    switch (action.type) {
      case 'setEvents':
        return action.payload;

      default:
        return state;
    }
  },
};
