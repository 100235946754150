import { mobiletrackerUrl } from 'apiConfig';
import queryString from 'query-string';

export const getSelf = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${mobiletrackerUrl}/json/self/`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((response) => {
    if (response.status !== 200) {
      // throw new Error(response.message);
      return { json: () => ({}) };
    }
    return response;
  })
  .then((res) => res.json());

export const fetchUsers = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${mobiletrackerUrl}/json/user/`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((res) => res.json());

export const fetchUser = (userCredentials, userId) => fetchUsers(userCredentials)
  .then((users) => users.find((u) => u.userId === userId));

export const createUser = (userCredentials, user) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${mobiletrackerUrl}/json/user/0`,
    {
      method: 'PUT',
      headers: { Authentication: `Bearer ${token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    },
  ))
  .then((res) => res.json());

export const saveUser = (userCredentials, user) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${mobiletrackerUrl}/json/user/0${user.userId}`,
    {
      method: 'PUT',
      headers: { Authentication: `Bearer ${token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(user),
    },
  ))
  .then((res) => res.json());

export const deleteUser = (userCredentials, userId) => {
  const url = `${mobiletrackerUrl}/json/user/${userId}`;
  return userCredentials
    .getIdToken()
    .then((token) => fetch(url, {
      method: 'DELETE',
      headers: {
        Authentication: `Bearer ${token}`,
      },
    }).then((res) => res.json()));
};

export const sendSms = (userCredentials, trackableIds, text) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    queryString.stringifyUrl({
      url: `${mobiletrackerUrl}/json/trackable/send_message`,
      query: {
        destinations: trackableIds,
        text,
      },
    }, { arrayFormat: 'comma' }),
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((res) => res.json());

export const fetchLastLocations = (userCredentials, trackableIds) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${mobiletrackerUrl}/locations/json/last/?trackableId=${encodeURIComponent(trackableIds.join(','))}`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((res) => res.json());

export const fetchLastLocationsAsMap = (userCredentials, trackableIds) => (
  fetchLastLocations(userCredentials, trackableIds)
    .then((lastLocations) => lastLocations.reduce((locationsObject, loc) => {
      // eslint-disable-next-line no-param-reassign
      locationsObject[loc.trackableId] = loc;
      return locationsObject;
    }, {}))
);

export const queryLocations = (userCredentials, trackableIds, start, end, limit) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${mobiletrackerUrl}/locations/json/by_trackable/?${queryString.stringify({
      trackableId: trackableIds,
      startTime: start && start.getTime(),
      endTime: end && end.getTime(),
      maxResults: limit,
    }, { arrayFormat: 'comma', skipNull: true })}`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((res) => res.json());

export const fetchSubscriptions = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${mobiletrackerUrl}/json/subscription/`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((res) => res.json());

export const fetchSubscription = (userCredentials, subscriptionId) => (
  fetchSubscriptions(userCredentials)
    // TODO: Use correct API once MT is fixed
    .then((list) => list.find((sub) => sub.subscriptionId === subscriptionId))
);

export const saveSubscription = (userCredentials, subscription) => (
  userCredentials.getIdToken()
    .then((token) => fetch(
      `${mobiletrackerUrl}/json/subscription/${subscription.subscriptionId}`,
      {
        method: 'PUT',
        headers: { Authentication: `Bearer ${token}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(subscription),
      },
    ))
);

export const deleteSubscription = (userCredentials, subscriptionId) => (
  userCredentials.getIdToken()
    .then((token) => fetch(
      `${mobiletrackerUrl}/json/subscription/${subscriptionId}`,
      {
        method: 'DELETE',
        headers: { Authentication: `Bearer ${token}`, 'Content-Type': 'application/json' },
      },
    ))
);

export const registerConnectionKey = (userCredentials) => (
  userCredentials.getIdToken()
    .then((token) => fetch(
      `${mobiletrackerUrl}/json/register_key/`,
      { headers: { Authentication: `Bearer ${token}` } },
    ))
    .then((res) => res.text())
);
