import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SendIcon from '@material-ui/icons/SendOutlined';
import { Chip, IconButton, TextField } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { Spinner } from '@mwp/frontend-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .recipients {
    display: flex;
    flex-wrap: wrap;
  }
  .chipWrapper {
    margin: ${({ theme }) => theme.spacing()}px;
    position: relative;
    border-radius: 100px;
    overflow: hidden;
    .busySpinner {
      font-size: 1rem;
    }

    .sent {
      background-color: ${({ theme }) => theme.palette.secondary.lighter};
    }
  }

  form {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    display: flex;
    position: relative;
    .msgField {
      flex-grow: 1;
    }

    .MuiInputBase-root{
      padding-right: 3rem;
    }

    button{
      position: absolute;
      right: 3px;
      top: 3px;
    }
  }
`;

const SendMessageForm = ({
  className,
  recipients,
  onRemoveRecipient,
  onSubmit,
  autoFocus,
}) => {
  const [message, setMessage] = React.useState('');
  const [validationMessage, setValidationError] = React.useState();
  const classes = ['SendMessageForm'];
  if (className) classes.push(className);

  function changeMessageValue(e) {
    setValidationError(null);
    setMessage(e.target.value);
  }

  function handleOnSubmit(e) {
    e.preventDefault();

    if (message.trim().length > 0) {
      onSubmit({
        creationTime: Date.now(),
        '@type': '.StringPayload',
        message,
      });
    } else {
      setValidationError('The message can\'t be empty');
    }
  }

  function handleDelete(recipient) {
    return () => {
      if (recipient.metadata.messagingState !== 'sending') {
        onRemoveRecipient(recipient);
      }
    };
  }

  return (
    <Wrapper className={classes.join(' ')}>
      <div className="recipients">
        {recipients.map((r) => {
          const deleteIcon = r.metadata.messagingState === 'sent' ? <DoneIcon /> : null;

          return (
            <div key={r.Name} className="chipWrapper">
              <Chip
                className={r.metadata.messagingState}
                label={r.Name}
                clickable
                color={
                  r.metadata.messagingState === 'error'
                    ? 'secondary'
                    : 'default'
                }
                variant="outlined"
                deleteIcon={deleteIcon}
                onDelete={handleDelete(r)}
              />
              {r.metadata.messagingState === 'sending' && (
                <Spinner relativeToParent />
              )}
            </div>
          );
        })}
      </div>
      <form onSubmit={handleOnSubmit}>
        <TextField
          className="msgField"
          id="outlined-helperText"
          label="Type your message here"
          name="message"
          error={!!validationMessage}
          helperText={validationMessage || null}
          variant="outlined"
          value={message}
          onChange={changeMessageValue}
          autoFocus={autoFocus}
        />
        <IconButton type="submit">
          <SendIcon />
        </IconButton>
      </form>
    </Wrapper>
  );
};

SendMessageForm.displayName = 'SendMessageForm';

SendMessageForm.propTypes = {
  className: PropTypes.string,
  recipients: PropTypes.arrayOf(PropTypes.shape({})),
  onRemoveRecipient: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

SendMessageForm.defaultProps = {
  className: null,
  recipients: [],
  autoFocus: true,
};

export default SendMessageForm;
