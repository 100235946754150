export default {
  navMenu: (state = { showLabels: true }, action) => {
    switch (action.type) {
      case 'toggleNavMenuLabels':
        return {
          showLabels:
            action.payload === undefined ? !state.showLabels : action.payload,
        };
      default:
        return state;
    }
  },
};
