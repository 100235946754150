import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import {
  user,
  layout,
  system,
  navMenu,
  events,
  logs,
  users,
  workers,
  workflowApps,
} from 'store/reducers';

export default createStore(
  combineReducers({
    ...user,
    ...layout,
    ...system,
    ...navMenu,
    ...events,
    ...logs,
    ...users,
    ...workers,
    ...workflowApps,
  }),
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);
