import React from 'react';
import PropTypes from 'prop-types';
import { Map, SplitView, Table } from '@mwp/frontend-components';

import Paper from '@material-ui/core/Paper';

import styled from 'styled-components';
import moment from 'moment';
import { Chip } from '@material-ui/core';

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  .mapWrapper,
  .tableWrapper {
    height: 100%;
    overflow: hidden;
  }

  .workersTable {
    height: 100%;
    overflow: auto;
  }
`;

const filterLabelsMap = {
  from: 'From',
  to: 'To',
  ids: 'Worker(s)',
  limit: 'Limit',
};

const canDelete = (filterName) => {
  if (filterName === 'ids' || filterName === 'limit') {
    return false;
  }
  return true;
};

const WorkerLocationsView = ({
  locations, onLocationSelected, mapProps, filters, onClearFilter, workers,
}) => {
  const [locationSelected, setLocationSelected] = React.useState();
  const [tableData, setTableData] = React.useState([]);

  const formatFilterValue = (filterName, value) => {
    if (filterName === 'from' || filterName === 'to') {
      return moment(value).format('DD/MM/YYYY HH:mm');
    }
    if (filterName === 'ids') {
      if (!Array.isArray(value)) {
        return workers[value].name;
      }
      const names = [];
      value.forEach((item) => {
        names.push(workers[item].name);
      });
      return names.join(', ');
    }
    return value;
  };

  function handleLocationSelection(data) {
    const location = locations.find((loc) => loc.id === data.id);
    setLocationSelected(location);
    onLocationSelected(location);
  }

  React.useEffect(() => {
    setTableData(
      locations.map(({
        id, name, timestamp, ...rest
      }) => ({
        ID: id,
        Name: name,
        Timestamp: moment(timestamp).format('YYYY/MM/DD HH:mm:ss'),
        metadata: {
          ...rest,
          selected: id === (locationSelected && locationSelected.id),
        },
      })),
    );
  }, [locations, locationSelected]);

  return (
    <Wrapper>
      <SplitView ratios="50%,50%" direction="column" p={2}>
        <Paper className="mapWrapper">
          <Map
            zoom={locationSelected ? 16 : mapProps.zoom}
            center={(!!locationSelected && locationSelected.position) || mapProps.center}
            onMarkerClick={handleLocationSelection}
            locations={locations.map((loc) => ({
              label: loc.name,
              id: loc.id,
              lat: loc.position.lat,
              lng: loc.position.lng,
              key: loc.id,
              metadata: {
                selected: loc.id === (locationSelected && locationSelected.id),
              },
            }))}
          />
        </Paper>
        <Paper style={{ background: 'transparent', boxShadow: 'none' }}>
          <div style={{ margin: 'auto 20px auto auto' }}>
            {
                    filters && Object.keys(filters).map((filterName) => (
                      filters[filterName]
                        && (
                        <Chip
                          key={filterName}
                          onDelete={
                             canDelete(filterName) ? () => { onClearFilter(filterName); } : null
                          }
                          label={`${filterLabelsMap[filterName]}: ${formatFilterValue(filterName, filters[filterName])}`}
                          style={{ margin: '3px' }}
                        />
                        )
                    ))
                  }
          </div>
          <Paper className="tableWrapper" style={{ background: 'transparent' }}>
            <Table className="workersTable" tableData={tableData} rowKey="ID" onRowClick={handleLocationSelection} />
          </Paper>
        </Paper>
      </SplitView>
    </Wrapper>
  );
};

WorkerLocationsView.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.shape({
        lng: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
      }).isRequired,
      timestamp: PropTypes.instanceOf(Date).isRequired,
    }),
  ).isRequired,
  mapProps: PropTypes.shape(Map.propTypes).isRequired,
  onLocationSelected: PropTypes.func.isRequired,
  filters: PropTypes.shape(filterLabelsMap).isRequired,
  onClearFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  workers: PropTypes.array.isRequired,
};

WorkerLocationsView.displayName = 'WorkerLocationsView';

export default WorkerLocationsView;
