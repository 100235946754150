import { listWorkflowApps } from 'gateways/WorkerManagerGateway';
import auth from 'lib';

const setWorkflowApps = (payload) => ({
  type: 'setWorkflowApps',
  payload,
});

// eslint-disable-next-line import/prefer-default-export
export const fetchWorkflowApp = () => (dispatch) => listWorkflowApps(auth.currentUser)
  .then((data) => {
    dispatch(setWorkflowApps(data));
  });
