/* eslint-disable */
import { schedulerServiceUrl } from 'apiConfig';

export const fetchEvents = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${schedulerServiceUrl}/events/`,
    { headers: { Authorization: `Bearer ${token}` } },
  ))
  .then((res) => res.json());

export const createEvent = (userCredentials, event) => {
  return userCredentials.getIdToken()
    .then((token) => fetch(`${schedulerServiceUrl}/events`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    }))
    .then((res) => {
      if (res.status !== 201) throw new Error(res);
    });
};

export const fetchEvent = (userCredentials, eventId) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${schedulerServiceUrl}/events/${eventId}`,
    { headers: { Authorization: `Bearer ${token}` } },
  ))
  .then((res) => res.json());

export const updateEvent = (userCredentials, event) => {
  return userCredentials.getIdToken()
    .then((token) => fetch(`${schedulerServiceUrl}/events/${event.id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event),
    }))
    .then((res) => {
      if (res.status !== 200) throw new Error(res);
    });
};

export const deleteEvent = (userCredentials, eventId) => {
  const url = `${schedulerServiceUrl}/events/${eventId}`;
  return userCredentials
    .getIdToken()
    .then((token) => fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res));
};
