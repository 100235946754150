import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const StyledUserAddDialog = styled(Dialog)`
`;

const UserAddDialog = ({
  className, open, onDismiss, onConfirm,
}) => {
  const [email, setEmail] = useState();
  const classes = ['UserAddDialog', className];
  const handleSubmit = (e) => {
    e.preventDefault();
    onConfirm(email);
    setEmail(null);
  };

  return (
    <StyledUserAddDialog
      className={classes.filter((c) => !!c).join(' ')}
      open={open}
      onClose={() => onDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add user</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary" disabled={!email} autoFocus>
          Add
        </Button>
      </DialogActions>
    </StyledUserAddDialog>
  );
};

UserAddDialog.displayName = 'UserAddDialog';

UserAddDialog.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

UserAddDialog.defaultProps = {
  className: null,
  open: false,
};

export default UserAddDialog;
