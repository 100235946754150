import React, { useEffect, useState } from 'react';
import NotificationsView from 'views/NotificationsView';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { Header, Spinner } from '@mwp/frontend-components';
import { setLayout } from '../store/actions';
import ToolbarContainer from './ToolbarContainer';
import NavigationMenuContainer from './NavigationMenuContainer';
import { fetchSubscriptions } from '../gateways';

const NotificationsContainer = ({ history }) => {
  const dispatch = useDispatch();
  const userCredentials = useSelector((state) => state.userCredentials);
  const selfUser = useSelector((state) => state.user);
  const isAdmin = selfUser.roles && selfUser.roles.includes('admin');
  const [subscriptions, setSubscriptions] = useState();

  const onSelected = (subscription) => {
    if (isAdmin) {
      history.push(`/notifications/${subscription.subscriptionId}/edit`);
    }
  };

  function handleAdd() {
    history.push('/notifications/add');
  }

  const addButton = <IconButton color="inherit" key="addButton" onClick={handleAdd}><AddIcon /></IconButton>;

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer buttons={isAdmin && [addButton]} />} />,
        aside: <NavigationMenuContainer />,
      }),
    );

    fetchSubscriptions(userCredentials).then(setSubscriptions);
  }, []);

  if (!subscriptions) {
    return <Spinner />;
  }

  return (
    <NotificationsView
      notifications={subscriptions}
      onSelected={onSelected}
    />
  );
};

NotificationsContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default NotificationsContainer;
