import { listWorkers as fetchApi } from 'gateways/WorkerManagerGateway';
import auth from 'lib';

const setWorkers = (payload) => ({
  type: 'setWorkers',
  payload,
});

export default () => (dispatch) => fetchApi(auth.currentUser).then((data) => {
  dispatch(setWorkers(data));
});
