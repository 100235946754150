import React from 'react';
import styled from 'styled-components';
import WidgetTitle from 'components/wmc/WidgetTitleAsLink';
import MailIcon from '@material-ui/icons/MailOutlined';
import { WidgetView } from '@mwp/frontend-components';
import Conversation from './Conversation';

const StyledMessagesWidget = styled(WidgetView)``;

const ConversationWrapper = styled.div`
  height: 400px;
`;

const MessagesWidget = ({ messages }) => (
  <StyledMessagesWidget
    title={<WidgetTitle title="Messages" to="/messages" icon={<MailIcon />} />}
  >
    <ConversationWrapper>
      <Conversation messages={messages} />
    </ConversationWrapper>
  </StyledMessagesWidget>
);

MessagesWidget.propTypes = Conversation.propTypes;

export default MessagesWidget;
