import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { Copyrights } from '@mwp/frontend-components';
import packageJson from '../../package.json';

const VersionWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing()}px;
  text-align: center;
  font-style: italic;
  opacity: 0.6;
`;

const StyledLoginView = styled.div`
  display: flex;
  height: 100vh;

  .image {
    background-image: url(https://source.unsplash.com/random?collection=353844/work);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .paper {
    margin: ${({ theme }) => theme.spacing(8, 4)};
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .avatar {
    margin: ${({ theme }) => theme.spacing(1, 1)};
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
  .form {
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(1, 1)};
  }
  .submit {
    margin: ${({ theme }) => theme.spacing(3, 0, 2)};
  }
`;

const LoginView = ({
  email, password, validationError, onSubmit, onChange, onAlertClose,
}) => {
  const { email: emailErr, password: passwordErr } = validationError;

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit();
  }

  const handleFieldChanged = (event) => onChange(event.target.name, event.target.value);

  return (
    <StyledLoginView>

      <Grid item xs={false} sm={4} md={7} className="image" />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className="paper">
          <Avatar className="avatar">
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className="form" noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              helperText={emailErr && emailErr.message}
              error={!!emailErr}
              onChange={handleFieldChanged}
              value={email}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              helperText={passwordErr && passwordErr.message}
              onChange={handleFieldChanged}
              value={password}
              error={!!passwordErr}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
            >
              Sign In
            </Button>
            <Grid pt={1} container>
              <Grid item xs>
                <Link component={RouterLink} to="/forgotten_password">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to="/register">
                  Don&apos;t have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
            <Box mt={5} style={{ display: 'flex', justifyContent: 'center' }}>
              <Copyrights />
              <VersionWrapper>
                <Typography variant="caption">
                  {`v${packageJson.version}`}
                </Typography>
              </VersionWrapper>
            </Box>
          </form>
        </div>
      </Grid>
      <Snackbar
        open={!!validationError.other}
        autoHideDuration={6000}
        onClose={onAlertClose}
      >
        <Alert
          severity="error"
          elevation={6}
          variant="filled"
          onClose={onAlertClose}
        >
          {validationError.other && validationError.other.message}
        </Alert>
      </Snackbar>
    </StyledLoginView>
  );
};

LoginView.displayName = 'LoginView';

LoginView.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  validationError: PropTypes.shape({
    email: PropTypes.shape({
      message: PropTypes.string,
    }),
    password: PropTypes.shape({
      message: PropTypes.string,
    }),
    other: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAlertClose: PropTypes.func.isRequired,
};

LoginView.defaultProps = {
  validationError: {},
};

export default LoginView;
