/* eslint-disable import/prefer-default-export */
import { eventLoggerUrl, eventLoggerSources } from 'apiConfig';
import queryString from 'query-string';

export const fetchEvents = ({
  userCredentials,
  from,
  to,
  originator,
  workflowAppId,
  groupId,
  eventType,
}) => userCredentials
  .getIdToken()
  .then((token) => {
    const params = {};
    params.contractId = groupId;
    if (originator) params.worker = originator;
    if (workflowAppId) params.workflowAppId = workflowAppId;
    const query = {
      sources: eventLoggerSources,
      from,
      to,
      param: JSON.stringify(params),
    };
    if (eventType) query.type = eventType;
    const url = queryString.stringifyUrl({
      url: `${eventLoggerUrl}/eventlogs`,
      query,
    });
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  })
  .then((res) => res.json())
  .then((data) => data
    .map((event) => {
      const wappIdParam = event.event_params.find((p) => p.key === 'workflowAppId');
      return {
        id: 0,
        timestamp: event.timestamp,
        originator: event.event_params.find((p) => p.key === 'worker').value,
        workflowAppId: (wappIdParam) ? wappIdParam.value : null,
        detail: event.message,
      };
    }));
