import { createMuiTheme } from '@material-ui/core/styles';
import lightGreen from '@material-ui/core/colors/lightGreen';
import emailPattern from 'images/email-pattern.png';

const mwPrimaryColor = {
  darker: '#0D4F76',
  dark: '#11689C',
  main: '#1580C0',
  light: '#d2d2d2',
  lighter: '#dde6ec',
};

const mwSecondaryColor = {
  darker: lightGreen[900],
  dark: lightGreen[700],
  main: lightGreen[500],
  light: lightGreen[300],
  lighter: lightGreen[100],
};

const mwAccentColor = '#FF7F01';

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      ...mwPrimaryColor,
    },
    secondary: {
      ...mwSecondaryColor,
    },
    accent: mwAccentColor,
    table: {
      body: '#f5f5f5',
      head: mwPrimaryColor.lighter,
      selectedRowBorder: mwAccentColor,
      placeholderText: mwPrimaryColor.light,
      light: {
        body: '#fff',
        head: '#fff',
        selectedRowBorder: mwAccentColor,
        headShadowColor: mwPrimaryColor.light,
      },
    },
    map: {
      marker: mwPrimaryColor.main,
      selectedMarker: mwAccentColor,
    },
    messages: {
      worker: '#9f5f38',
    },
  },
  backgrounds: {
    messages: `url('${emailPattern}')`,
  },
});

export const alternateTheme = createMuiTheme();
