import { userServiceUrl } from 'apiConfig';

export class UserNotFound extends Error {
}

export const getSelf = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/self`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((response) => {
    switch (response.status) {
      case 404:
        return null;
      case 200:
        return response;
      default:
        throw new Error();
    }
  })
  .then((res) => res && res.json());

export const register = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/register`,
    {
      method: 'POST',
      headers: {
        Authentication: `Bearer ${token}`,
      },
    },
  ))
  .then((response) => {
    if (response.status !== 200) {
      throw new Error(response.message);
    }
    return response;
  })
  .then((res) => res.json());

export const getUsers = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/user`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((response) => {
    if (response.status !== 200) {
      // throw new Error(response.message);
      return { json: () => ({}) };
    }
    return response;
  })
  .then((res) => res.json());

export const getUser = (userCredentials, email) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/user/${email}`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((response) => {
    if (response.status !== 200) {
      // throw new Error(response.message);
      return { json: () => ({}) };
    }
    return response;
  })
  .then((res) => res.json());

export const setRoles = (userCredentials, email, roles) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/user/${encodeURIComponent(email)}/roles`, {
      method: 'POST',
      body: JSON.stringify(roles),
      headers: {
        Authentication: `Bearer ${token}`,
        'content-type': 'application/json',
      },
    },
  ))
  .then((response) => {
    if (response.status !== 204) {
      throw new Error();
    }
    return response;
  });

export const addUser = (userCredentials, email) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/user/${encodeURIComponent(email.toLowerCase())}`, {
      method: 'POST',
      headers: {
        Authentication: `Bearer ${token}`,
      },
    },
  ))
  .then((response) => {
    if (response.status === 404) {
      throw new UserNotFound();
    } else if (response.status !== 204) {
      throw new Error(response.message);
    }
    return response;
  });

export const deleteUser = (userCredentials, email) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/user/${email}`, {
      method: 'DELETE',
      headers: {
        Authentication: `Bearer ${token}`,
      },
    },
  ))
  .then((response) => {
    if (response.status !== 200) {
      // throw new Error(response.message);
      return { json: () => ({}) };
    }
    return response;
  })
  .then((res) => res.json());

export const getGroups = (userCredentials) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/group`,
    { headers: { Authentication: `Bearer ${token}` } },
  ))
  .then((response) => {
    if (response.status !== 200) {
      // throw new Error(response.message);
      return { json: () => ({}) };
    }
    return response;
  })
  .then((res) => res.json());

export const setActiveGroup = (userCredentials, groupId) => userCredentials
  .getIdToken()
  .then((token) => fetch(
    `${userServiceUrl}/api/self/activeGroup/${groupId}`,
    {
      method: 'POST',
      headers: { Authentication: `Bearer ${token}` },
    },
  ))
  .then((response) => {
    if (response.status !== 200) {
      // throw new Error(response.message);
      return response;
    }
    return response;
  });
