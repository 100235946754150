import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, ButtonBase } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const WidgetTitle = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
  display: flex;
  align-items: center;
  >svg {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const WidgetTitleLink = ({ title, to, icon }) => (
  <ButtonBase color="textPrimary" component={RouterLink} to={to}>
    <WidgetTitle variant="h6">
      {icon}
      {title}
    </WidgetTitle>
  </ButtonBase>
);

WidgetTitleLink.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

WidgetTitleLink.defaultProps = {
  icon: null,
};

export default WidgetTitleLink;
