import React from 'react';
import PropTypes from 'prop-types';
import ChartWidget from 'components/wmc/ChartWidget';

import { PieChart, Pie, Cell } from 'recharts';

const PieChartWidget = ({
  className, data, title, placeholder, onCellClick,
}) => {
  const classes = ['PieChartWidget'];
  if (className) classes.push(className);

  const colStep = 360 / data.length;
  const colors = data.map(
    (e, index) => `hsla(${colStep * index}, 40%, 60%, 1)`,
  );

  function onChartClick(entry) {
    return () => {
      if (onCellClick) onCellClick(entry);
    };
  }
  return (
    <ChartWidget
      title={title}
      placeholder={placeholder}
      className={classes.join(' ')}
    >
      {data.length > 0 && (
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            isAnimationActive={false}
            outerRadius="60%"
            label={({ name, value }) => `${name} (${value})`}
            dataKey="value"
            nameKey="name"
            style={{ cursor: 'pointer' }}
          >
            {data.map((entry, index) => (
              <Cell
                onClick={onChartClick(entry)}
                key={entry.name}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      )}
    </ChartWidget>
  );
};

PieChartWidget.displayName = 'PieChartWidget';

PieChartWidget.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placeholder: PropTypes.string,
  onCellClick: PropTypes.func,
};

PieChartWidget.defaultProps = {
  className: null,
  data: [],
  title: null,
  placeholder: undefined,
  onCellClick: undefined,
};

export default PieChartWidget;
