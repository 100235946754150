import React from 'react';
import PropTypes from 'prop-types';
import { Map, WidgetView } from '@mwp/frontend-components';
import styled from 'styled-components';

const Wrapper = styled(WidgetView)`
  min-width: 300px;
  height: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 500px;
  }
  .mapWrapper{
    min-height: 40vh;
    height: 100%;
    position: relative;
  }
`;

const MapWidget = ({
  center,
  zoom,
  locations,
  onMarkerClick,
}) => (
  <Wrapper title="Last locations">
    <div className="mapWrapper">
      <Map center={center} zoom={zoom} locations={locations} onMarkerClick={onMarkerClick} />
    </div>
  </Wrapper>
);

MapWidget.displayName = 'MapWidget';

MapWidget.propTypes = {
  center: PropTypes.shape(Map.propTypes.center).isRequired,
  ...Map.propTypes,
};

export default MapWidget;
