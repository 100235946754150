import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AsideToggle } from '@mwp/frontend-components';
import { toggleAside } from 'store/actions';

const AsideToggleContainer = () => {
  const dispatch = useDispatch();
  const showAside = useSelector((state) => state.layout.showAside);

  function onClick() {
    dispatch(toggleAside());
  }

  return <AsideToggle showAside={showAside} onClick={onClick} />;
};

export default AsideToggleContainer;
