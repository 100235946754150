import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import auth from 'lib/auth';
import { useHistory } from 'react-router';
import ForgottenPasswordView from '../views/ForgottenPasswordView';
import { addMessage, setAppBusy } from '../store/actions';

const passwordResetMessage = {
  severity: 'info',
  text: 'Please check you email for resetting your password',
};

const ForgottenPasswordContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState('');

  const handleSubmit = (email) => {
    dispatch(setAppBusy(true));
    auth.sendPasswordResetEmail(email)
      .then(() => {
        history.push('/login');
        dispatch(addMessage(passwordResetMessage));
      })
      .catch((err) => {
        switch (err.code) {
          case 'auth/user-not-found':
            setEmailError('Could not find the email address');
            break;
          case 'auth/invalid-email':
            setEmailError('The email address is badly formatted');
            break;
          default:
            dispatch(addMessage({
              severity: 'error',
              text: 'Could not send the password rest link',
              autoHideDuration: 6000,
            }));
        }
      })
      .finally(() => dispatch(setAppBusy(false)));
  };

  return (
    <ForgottenPasswordView
      onSubmit={handleSubmit}
      validationError={{ email: emailError }}
    />
  );
};

export default ForgottenPasswordContainer;
