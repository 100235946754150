import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { ViewWrapper, Table } from '@mwp/frontend-components';

const addressTypeFieldMap = {
  'com.synergizeit.mobiletracker.notification.subscription.PhoneNumber': 'digits',
  'com.synergizeit.mobiletracker.notification.subscription.HttpAddress': 'url',
  'com.synergizeit.mobiletracker.notification.subscription.EmailAddress': 'addressString',
};

function mapAddress(address) {
  const addressField = addressTypeFieldMap[address['@class']];
  return (addressField && address[addressField]) || <i>Unknown</i>;
}

const eventTypeLabelMap = {
  GEOFENCE_EVENT: 'Geofence',
  LOCATION_EVENT: 'Location',
  TRACKABLE_EVENT: 'Trackable',
  USER_EVENT: 'User',
};

function mapNotification(subscription) {
  return {
    Type: eventTypeLabelMap[subscription.eventType] || subscription.eventType,
    Destination: mapAddress(subscription.address),
    subscriptionId: subscription.subscriptionId,
  };
}

const NoNotificationsPaper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

const NotificationsView = ({ notifications, onSelected }) => (
  <ViewWrapper>
    {notifications.length > 0 && (
      <Table
        tableData={notifications.map(mapNotification)}
        rowKey="subscriptionId"
        onRowClick={onSelected}
        hideKey
        theme="light"
      />
    )}
    {!notifications.length && (
      <NoNotificationsPaper p={2}>
        You don&apos;t have any notification setup
      </NoNotificationsPaper>
    )}
  </ViewWrapper>
);

NotificationsView.propTypes = {
  onSelected: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.shape({
      '@class': PropTypes.string.isRequired,
      url: PropTypes.string,
      addressString: PropTypes.string,
      digits: PropTypes.string,
    }).isRequired,
    type: PropTypes.string.isRequired,
    subscriptionID: PropTypes.number.isRequired,
  })).isRequired,
};

export default NotificationsView;
