import { fetchEvents as fetchApi } from 'gateways/EventLoggerGateway';
import auth from 'lib';

export const setLogs = (payload) => ({
  type: 'setLogs',
  payload,
});

export const fetchLogs = ({
  groupId, from, eventType,
}) => (dispatch) => fetchApi({
  userCredentials: auth.currentUser,
  groupId,
  from,
  eventType,
}).then((data) => {
  dispatch(setLogs(data));
});
