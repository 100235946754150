/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ViewPaper, ConfirmationDialog } from '@mwp/frontend-components';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  TextField,
  Button,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

const CalendarEditView = ({
  event, onSave, onCancel, onDelete,
}) => {
  const [value, setValue] = useState(event);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value.startTime === null) {
      alert('Start time cannot be empty');
      return;
    }
    if (value.endTime !== null && value.endTime < value.startTime) {
      alert('End time cannot be before start time');
      return;
    }
    if (value.notifyTimeBefore === 'none') {
      value.notifyTimeBefore = null;
    } else {
      value.notifyTimeBefore = parseInt(value.notifyTimeBefore, 10);
    }
    onSave(value);
  };

  const changeFieldValue = (e) => {
    const elementValue = ['checkbox', 'radio'].includes(e.target.type)
      ? e.target.checked
      : e.target.value;
    setValue({ ...value, [e.target.name]: elementValue });
  };

  const onConfirmDelete = () => {
    setOpenDeleteConfirmDialog(false);
    onDelete(event.id);
  };

  return (
    <>
      <ViewPaper>
        <Typography variant="h6">{event.id ? 'Edit' : 'New Event'}</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            name="title"
            required
            label="Title"
            placeholder="Event title"
            fullWidth
            margin="normal"
            value={value ? value.title : ''}
            onChange={changeFieldValue}
          />
          <TextField
            name="description"
            label="Description"
            placeholder="Event description"
            fullWidth
            margin="normal"
            multiline
            rows={2}
            rowsMax={Infinity}
            value={value ? value.description : ''}
            onChange={changeFieldValue}
          />
          <FormControl fullWidth margin="normal">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                ampm={false}
                clearable
                required
                fullWidth
                format="dd MMMM yyyy HH:mm"
                value={value && value.startTime ? new Date(value.startTime * 1000) : null}
                name="startTime"
                label="Start Time"
                onChange={(d) => setValue({ ...value, startTime: d ? d.getTime() / 1000 : null })}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                ampm={false}
                clearable
                fullWidth
                format="dd MMMM yyyy HH:mm"
                value={value && value.endTime ? new Date(value.endTime * 1000) : null}
                name="endTime"
                label="End Time"
                onChange={(d) => setValue({ ...value, endTime: d ? d.getTime() / 1000 : null })}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="repeat">Repeat</InputLabel>
            <Select name="repeat" value={value ? value.repeat : 'never'} onChange={changeFieldValue}>
              <MenuItem value="never">Never</MenuItem>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="notifyTimeBefore">Notification</InputLabel>
            <Select name="notifyTimeBefore" value={value ? value.notifyTimeBefore : 'none'} onChange={changeFieldValue}>
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="0">At start</MenuItem>
              <MenuItem value="300">5 mins before</MenuItem>
              <MenuItem value="600">10 mins before</MenuItem>
              <MenuItem value="900">15 mins before</MenuItem>
              <MenuItem value="1800">30 mins before</MenuItem>
              <MenuItem value="3600">1 hour before</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={(
              <Checkbox
                checked={value ? value.public : false}
                onChange={changeFieldValue}
                name="public"
                color="primary"
                disabled={event.id && event.public}
              />
            )}
            label="Public"
          />
          <Box display="flex" pt={2}>
            <Box flexGrow={1}>
              {value && value.id && (
                <Button color="secondary" onClick={() => setOpenDeleteConfirmDialog(true)}>
                  Delete
                </Button>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" onClick={onCancel}>Cancel</Button>
              <Button type="submit" color="primary" variant="outlined">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </ViewPaper>
      {event && (
        <ConfirmationDialog
          dismissButtonLabel="Cancel"
          open={openDeleteConfirmDialog}
          title="Delete Event"
          content={(
            <>
              <p>
                Are you sure you want to delete the event&nbsp;
                <strong>{event.title}</strong>
                ?
              </p>
              <p>This operation can not be undone</p>
            </>
          )}
          confirmButtonLabel="Delete"
          handleConfirm={onConfirmDelete}
          handleDismiss={() => setOpenDeleteConfirmDialog(false)}
        />
      )}
    </>
  );
};

CalendarEditView.propTypes = {
  event: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CalendarEditView;
