import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

const ConditionalRoute = (
  {
    conditions, component, ...rest
  },
) => (
  <Route
    {...rest}
    render={({ location, match, history }) => {
      const conditionMatched = conditions.find(({ condition }) => condition);

      return conditionMatched ? <Redirect to={{ pathname: conditionMatched.redirectTo }} />
        : React.createElement(component, { location, match, history });
    }}
  />
);

ConditionalRoute.displayName = 'ConditionalRoute';

ConditionalRoute.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.shape({
    condition: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string.isRequired,
  })).isRequired,
  component: PropTypes.elementType.isRequired,
};

export default ConditionalRoute;
