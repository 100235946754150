const defaultMobiletrackerUrl = 'https://live.mobileworkerplus.com/mobiletracker';
const defaultWorkermanagerUrl = 'https://live.mobileworkerplus.com/worker_manager';
const defaultUserServiceUrl = 'https://live.mobileworkerplus.com/user_service';
const defaultEventLoggerUrl = 'https://europe-west1-wfm-service-test-551087.cloudfunctions.net/eventlogger-service'; // TODO: ??
const defaultSchedulerServiceUrl = 'https://europe-west1-wfm-service-154843.cloudfunctions.net/wfm-scheduler';

export const mobiletrackerUrl = (window.wmcConfig && window.wmcConfig.mobiletrackerUrl)
  || defaultMobiletrackerUrl;

export const workermanagerUrl = (window.wmcConfig && window.wmcConfig.workermanagerUrl)
  || defaultWorkermanagerUrl;

export const userServiceUrl = (window.wmcConfig && window.wmcConfig.userServiceUrl)
  || defaultUserServiceUrl;

export const eventLoggerUrl = (window.wmcConfig && window.wmcConfig.eventLoggerUrl)
  || defaultEventLoggerUrl;

export const eventLoggerSources = (window.wmcConfig && window.wmcConfig.eventLoggerSources)
  || '';

export const schedulerServiceUrl = (window.wmcConfig && window.wmcConfig.schedulerServiceUrl)
  || defaultSchedulerServiceUrl;
