export default {
  logs: (state = [], action) => {
    switch (action.type) {
      case 'setLogs':
        return action.payload;

      default:
        return state;
    }
  },
};
