import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationEditView from 'views/NotificationEditView';
import { setAppBusy, setLayout } from 'store/actions';
import { Header, Spinner } from '@mwp/frontend-components';
import ToolbarContainer from 'containers/ToolbarContainer';
import NavigationMenuContainer from 'containers/NavigationMenuContainer';
import { deleteSubscription, fetchSubscription, saveSubscription } from 'gateways';

const NotificationEditContainer = ({ match, history }) => {
  const dispatch = useDispatch();
  const userCredentials = useSelector((state) => state.userCredentials);

  const loggedInUser = useSelector((state) => state.user);
  const subscriptionId = match.params && match.params.subscriptionId
    ? parseInt(match.params.subscriptionId, 10) : 0;
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    dispatch(setLayout({
      header: <Header toolbar={<ToolbarContainer />} />,
      aside: <NavigationMenuContainer />,
    }));
  }, []);

  useEffect(() => {
    if (subscriptionId > 0) {
      fetchSubscription(userCredentials, subscriptionId)
        .then(setSubscription);
    }
  }, []);

  const handleDelete = () => {
    deleteSubscription(userCredentials, subscriptionId)
      .then(() => history.goBack());
  };

  const handleSubmit = (updatedSubscription) => {
    dispatch(setAppBusy(true));
    saveSubscription(userCredentials,
      {
        ...updatedSubscription,
        subscriptionId: updatedSubscription.subscriptionId || 0,
        ownerId: loggedInUser.group.groupId,
      })
      .then(() => dispatch(setAppBusy(false)))
      .finally(() => history.goBack());
  };

  return (
    <>
      {subscriptionId > 0 && !subscription && <Spinner />}
      {(subscriptionId === 0 || subscription) && (
        <NotificationEditView
          subscription={subscription}
          onDelete={handleDelete}
          onSubmit={handleSubmit}
          onChange={() => {
          }}
          onCancel={() => history.goBack()}
        />
      )}
    </>
  );
};

NotificationEditContainer.displayName = 'NotificationEditcontainer';

NotificationEditContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subscriptionId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape(
    { goBack: PropTypes.func.isRequired },
  ).isRequired,
};

export default NotificationEditContainer;
