import React from 'react';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

const StyledLoadingView = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15%;
  display: flex;
  align-items: center;
  >* {
    flex-grow: 1;
  }
`;

const LoadingView = () => (<StyledLoadingView><LinearProgress /></StyledLoadingView>);

export default LoadingView;
