import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import { clearMessage, toggleAside } from 'store/actions';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const DrawerContainer = styled.div`
  .closeBtn {
    position: absolute;
    z-index: 1;
    right: 0;
  }
`;

const MainLayoutContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { header, aside, showAside } = useSelector((state) => state.layout);
  const messages = useSelector((state) => state.messages);
  const theme = useTheme();
  const mqXs = useMediaQuery(theme.breakpoints.only('xs'));
  const location = useLocation();
  const [snackbarOpened, setSnackbarOpened] = useState(false);

  React.useEffect(() => {
    // autoclosing the aside panel when navigating to another page
    if (mqXs) {
      dispatch(toggleAside(false));
    }
  }, [location]);

  React.useEffect(() => {
    dispatch(toggleAside(!mqXs));
  }, [mqXs]);

  function hideAside() {
    dispatch(toggleAside(false));
  }

  useEffect(() => {
    setSnackbarOpened(!!messages.length);
  }, [messages]);

  const message = messages[0] || {};

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway' && message.disableClickaway) {
      return;
    }
    dispatch(clearMessage(message));
  };

  return (
    <>
      <MainLayout
        header={header}
        aside={aside}
        showAside={!mqXs && showAside}
        message={(
          <Snackbar
            key={new Date().getTime()}
            open={snackbarOpened}
            autoHideDuration={message.autoHideDuration}
            onClose={handleMessageClose}
          >
            <Alert
              severity={message.severity}
              elevation={6}
              variant="filled"
              onClose={handleMessageClose}
            >
              {message.text}
            </Alert>
          </Snackbar>
        )}
      >
        {children}
      </MainLayout>
      <Hidden smUp>
        <Drawer
          anchor="left"
          open={showAside}
          onClose={hideAside}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerContainer>
            <IconButton className="closeBtn" onClick={hideAside}>
              <Close />
            </IconButton>
            {aside}
          </DrawerContainer>
        </Drawer>
      </Hidden>
    </>
  );
};

MainLayoutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default MainLayoutContainer;

MainLayoutContainer.displayName = 'MainLayoutContainer';
