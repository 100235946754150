import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Swipeable } from 'react-swipeable';
import Paper from '@material-ui/core/Paper';
import { Map, SplitView, Table } from '@mwp/frontend-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WorkerDetails from 'components/wmc/WorkerDetails';
import SendSMSDialog from 'components/wmc/SendSMSDialog';

const StyledWorkersView = styled.div`
  height: 100%;
  width: 100%;

  .MuiPaper-root {
    height: 100%;
    overflow: hidden;
  }

  .workerDetailsWrapper {
    height: 100%;
    position: relative;

    .workerDetails {
      height: 100%;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        height: 4px;
        top: calc(50% - 2px);
        width: 4px;
        background-color: rgba(0,0,0,0.2);
        left: 6px;
        border-radius: 10px;
        box-shadow: 0 -8px 0px rgba(0,0,0,0.2), 0 8px 0px rgba(0,0,0,0.2);
      }
    }
  }

  .closeDetailsBtn {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index:2;
  }
  .workersTable {
    height: 100%;
    overflow: auto;
  }
`;

const WorkersView = ({
  mapProps, workers, canEdit, onSendSms,
}) => {
  const theme = useTheme();
  const mqXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedWorker, setSelectedWorker] = useState();
  const [detailsSplitRatio, setDetailsSplitRatio] = React.useState('100% 0%');
  const [tableSplitRatio, setTableSplitRatio] = React.useState(
    mqXs ? '40% 60%' : '30% 70%',
  );

  const [animateResize, setAnimateResize] = React.useState(true);
  const [displaySmsDialog, setDisplaySmsDialog] = React.useState(false);
  const [textError, setTextError] = React.useState('');

  const getWorkerLocations = () => workers
    .map((worker, idx) => ({ ...worker, idx }))
    .filter((worker) => worker.location)
    .map((worker) => ({
      ...worker.location,
      label: worker.name,
      metadata: {
        ...worker,
        selected: selectedWorker && (selectedWorker.trackableId === worker.trackableId),
      },
    }));

  const tableData = workers.map((w) => ({
    Username: w.username,
    Name: w.name,
    metadata: {
      ...w,
      selected: selectedWorker && (selectedWorker.trackableId === w.trackableId),
    },
  }));

  function handleWorkerSelection({ metadata }) {
    if (selectedWorker && selectedWorker.trackableId === metadata.trackableId) {
      setSelectedWorker(null);
    } else {
      setSelectedWorker(metadata);
    }
  }

  React.useEffect(() => {
    if (selectedWorker) {
      setDetailsSplitRatio(mqXs ? '0%,100%' : '40%,60%');
    } else {
      setDetailsSplitRatio('100%,0%');
    }

    setTableSplitRatio(mqXs ? '40%,60%' : '30%,70%');
  }, [mqXs, selectedWorker]);

  const viewsRefs = [];

  function onSwiped(e) {
    setAnimateResize(true);
    if (e.absX > 50) {
      setSelectedWorker(null);
    } else {
      viewsRefs[0].current.style = 'width: 0px;';
      viewsRefs[1].current.style = 'width: 100%;';
    }
  }

  function swiping(e) {
    if (e.first) {
      setAnimateResize(false);
    }
    if (e.deltaX < 0) {
      const x = e.absX;
      viewsRefs[0].current.style = `width:${x}px;`;
      viewsRefs[1].current.style = `width: calc(100% - ${x}px);`;
    } else {
      viewsRefs[0].current.style = 'width: 0px;';
      viewsRefs[1].current.style = 'width: 100%;';
    }
  }

  const handleSendSms = (text) => {
    if (onSendSms) {
      const sendSmsResult = onSendSms(selectedWorker.msisdn, text);
      if (sendSmsResult && sendSmsResult.then) {
        sendSmsResult
          .then(() => setDisplaySmsDialog(false))
          .catch(() => setTextError('Could not send SMS'));
        return true;
      }
    }
    setDisplaySmsDialog(false);
    return true;
  };

  return (
    <StyledWorkersView
      className="WorkersView"
    >
      <SplitView
        className="map_workersTable"
        direction="column"
        ratios={tableSplitRatio}
        p={mqXs ? 1 : 2}
      >
        <Paper>
          <SplitView
            className="map_details"
            animate={animateResize}
            ratios={detailsSplitRatio}
            viewsRefs={viewsRefs}
          >
            <Map
              mapId="workersMap"
              zoom={mapProps.zoom}
              center={
                (!mqXs && selectedWorker && selectedWorker.location)
                || mapProps.center
              }
              onMarkerClick={handleWorkerSelection}
              locations={getWorkerLocations()}
            />

            {selectedWorker && (
              <div className="workerDetailsWrapper">
                <IconButton
                  className="closeDetailsBtn"
                  type="button"
                  onClick={() => {
                    setSelectedWorker(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {mqXs && (
                  <Swipeable
                    delta={5}
                    className="workerDetails"
                    onSwiping={swiping}
                    onSwiped={onSwiped}
                  >
                    <WorkerDetails
                      worker={selectedWorker}
                      canEdit={canEdit}
                      onMsisdnClick={() => setDisplaySmsDialog(true)}
                    />
                  </Swipeable>
                )}
                {!mqXs && (
                  <WorkerDetails
                    worker={selectedWorker}
                    canEdit={canEdit}
                    onMsisdnClick={() => setDisplaySmsDialog(true)}
                  />
                )}
              </div>
            )}
          </SplitView>
        </Paper>

        <Paper>
          <Table
            className="workersTable"
            tableData={tableData}
            onRowClick={handleWorkerSelection}
            rowKey="Username"
            theme="light"
          />
        </Paper>
      </SplitView>
      <SendSMSDialog
        open={displaySmsDialog}
        onClose={() => setDisplaySmsDialog(false)}
        onSend={handleSendSms}
        textError={textError}
      />
    </StyledWorkersView>
  );
};

WorkersView.propTypes = {
  workers: PropTypes.arrayOf(
    PropTypes.shape({
      trackableId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  mapProps: PropTypes.shape({
    zoom: PropTypes.number.isRequired,
    center: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  canEdit: PropTypes.bool,
  onSendSms: PropTypes.func,
};

WorkersView.defaultProps = {
  canEdit: false,
  onSendSms: null,
};

export default WorkersView;
