import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Header } from '@mwp/frontend-components';
import { setLayout } from '../store/actions';
import ToolbarContainer from './ToolbarContainer';
import NavigationMenuContainer from './NavigationMenuContainer';

const PluginContainer = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer />} />,
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  return children;
};

PluginContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PluginContainer;
