import { fetchEvents as fetchApi } from 'gateways/EventLoggerGateway';
import auth from 'lib';

const setEvents = (payload) => ({
  type: 'setEvents',
  payload,
});

// eslint-disable-next-line import/prefer-default-export
export const fetchEvents = ({
  from, to, originator,
  workflowAppId, groupId,
}) => (dispatch) => fetchApi({
  userCredentials: auth.currentUser,
  from,
  to,
  originator,
  workflowAppId,
  groupId,
})
  .then((data) => {
    dispatch(setEvents(data));
  });
