import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Header, Spinner } from '@mwp/frontend-components';
import UsersView from 'views/UsersView';
import { useUsers } from 'hooks';
import useUserServiceGateways from 'hooks/useUserServiceGateways';
import {
  addMessage, fetchUsers, setAppBusy, setLayout,
} from 'store/actions';
import ToolbarContainer from 'containers/ToolbarContainer';
import NavigationMenuContainer from 'containers/NavigationMenuContainer';
import { UserNotFound } from 'gateways/UserServiceGateway';

const UsersContainer = ({ history }) => {
  const dispatch = useDispatch();
  const selfUser = useSelector((state) => state.user);
  const isAdmin = selfUser.roles && selfUser.roles.includes('admin');
  const [addingUser, setAddingUser] = useState(false);
  const { addUser } = useUserServiceGateways();
  const users = useUsers();

  const onUserSelected = (email) => {
    if (isAdmin) {
      history.push(`/users/${email}/edit`);
    }
  };

  const addUserButton = <IconButton color="inherit" key="addUserButton" onClick={() => setAddingUser(true)}><AddIcon /></IconButton>;

  const handleAddUserError = (err) => {
    const text = err instanceof UserNotFound
      ? 'User not found'
      : 'Something went wrong, could not add the user to this group';
    dispatch(addMessage({ severity: 'error', text }));
  };

  const handleAddUser = (email) => {
    if (email) {
      dispatch(setAppBusy(true));
      addUser(email)
        .then(() => dispatch(addMessage({
          severity: 'info',
          text: 'User added',
        })))
        .then(() => dispatch(fetchUsers()))
        .catch(handleAddUserError)
        .finally(() => {
          setAddingUser(false);
          dispatch(setAppBusy(false));
        });
    } else {
      setAddingUser(false);
    }
  };

  useEffect(() => {
    dispatch(
      setLayout({
        header: <Header toolbar={<ToolbarContainer buttons={isAdmin && [addUserButton]} />} />,
        aside: <NavigationMenuContainer />,
      }),
    );
  }, [dispatch]);

  return (
    <>
      {!users && <Spinner relativeToParent />}
      {!!users && (
        <UsersView
          tableData={users || []}
          tableTitle="Users"
          onRowClick={onUserSelected}
          onAddUser={handleAddUser}
          displayAddDialog={addingUser}
        />
      )}
    </>
  );
};

export default UsersContainer;

UsersContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
