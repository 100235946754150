import React from 'react';
import PropTypes from 'prop-types';

import WidgetsDisplay from './WidgetDisplay';

const HomeView = ({ className, children }) => {
  const classes = ['HomeView'];
  if (className) classes.push(className);

  return (
    <div className={classes.join(' ')}>
      <WidgetsDisplay>
        {children}
      </WidgetsDisplay>
    </div>
  );
};

HomeView.displayName = 'HomeView';

HomeView.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

HomeView.defaultProps = {
  className: null,
};

export default HomeView;
