/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from '../firebaseConfig';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export default auth;
