import { useSelector } from 'react-redux';
import {
  setRoles, getUser, getUsers, deleteUser, addUser,
} from 'gateways/UserServiceGateway';

export default () => {
  const userCredentials = useSelector((state) => state.userCredentials);

  return {
    setRoles: (email, roles) => setRoles(userCredentials, email, roles),
    getUser: (email) => getUser(userCredentials, email),
    getUsers: () => getUsers(userCredentials),
    deleteUser: (email) => deleteUser(userCredentials, email),
    addUser: (email) => addUser(userCredentials, email),
  };
};
