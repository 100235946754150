import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleAside, logout,
} from 'store/actions';
import { useTheme } from '@material-ui/core/styles';
import { Toolbar } from '@mwp/frontend-components';
import AsideToggleContainer from 'containers/AsideToggleContainer';
import NavMenuLabelsToggleContainer from 'containers/NavMenuLabelsToggleContainer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { setActiveGroup } from 'gateways/UserServiceGateway';
import ChangePasswordContainer from './ChangePasswordContainer';

const ToolbarContainer = ({ buttons }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mqSm = useMediaQuery(theme.breakpoints.up('sm'));
  const mqXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [displayChangePassword, setDisplayChangePassword] = useState(false);
  const groups = useSelector((state) => state.groups);
  const user = useSelector((state) => state.user);
  const userCredentials = useSelector((state) => state.userCredentials);

  React.useEffect(() => {
    dispatch(toggleAside(mqSm));
  }, [dispatch, mqSm]);

  return (
    <>
      <ChangePasswordContainer
        onDismiss={() => setDisplayChangePassword(false)}
        open={displayChangePassword}
      />
      <Toolbar
        groups={groups}
        activeGroupId={user && user.activeGroupId}
        buttons={buttons}
        onLogoutClick={() => dispatch(logout())}
        onChangePasswordClick={() => setDisplayChangePassword(true)}
        onGroupChange={
          (groupId) => setActiveGroup(userCredentials, groupId)
            .then(() => userCredentials.getIdToken(true))
            .then(() => window.location.reload())
          // TODO: We should really not reload the whole page
        }
        asideToggle={mqXs ? <AsideToggleContainer /> : null}
        navMenuLabelsToggle={mqSm ? <NavMenuLabelsToggleContainer /> : null}
      />
    </>
  );
};

ToolbarContainer.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.element),
};

ToolbarContainer.defaultProps = {
  buttons: [],
};

ToolbarContainer.displayName = 'ToolbarContainer';

export default ToolbarContainer;
