import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import HomeContainer from 'containers/HomeContainer';
import LoginContainer from 'containers/LoginContainer';
import RegistrationContainer from 'containers/RegistrationContainer';
import OnboardContainer from 'containers/OnboardContainer';
import WorkersContainer from 'containers/WorkersContainer';
import { useDispatch, useSelector } from 'react-redux';
import WorkerLocationsContainer from 'containers/WorkerLocationsContainer';
import ConditionalRoute from 'ConditionalRoute';
import UsersContainer from 'containers/UsersContainer';
import UserEditContainer from 'containers/UserEditContainer';
import WorkerEditContainer from 'containers/WorkerEditContainer';
import BaseApp from 'BaseApp';
import EventsContainer from 'containers/EventsContainer';
import CalendarContainer from 'containers/CalendarContainer';
import NotificationsContainer from 'containers/NotificationsContainer';
import NotificationEditContainer from 'containers/NotificationEditContainer';
import MessagesContainer from 'containers/MessagesContainer';
import ForgottenPasswordContainer from 'containers/ForgottenPasswordContainer';
import { usePlugins } from 'context/PluginContext';
import PluginsContextProvider from 'context/PluginsContextProvider';
import PluginContainer from 'containers/PluginContainer';
import { setAppBusy } from 'store/actions';

const App = () => {
  const user = useSelector((state) => state.user);
  const userCredentials = useSelector((state) => state.userCredentials);
  const { plugins, pluginsInitialised } = usePlugins();
  const dispatch = useDispatch();

  const onboardConditions = [
    { condition: !userCredentials, redirectTo: '/login' },
    { condition: !!user && !!user.email && !!user.activeGroupId, redirectTo: '/' },
  ];
  const loginConditions = [
    { condition: !!user && !!user.email, redirectTo: '/' },
    { condition: !!user && !user.email, redirectTo: '/onboard' },
  ];
  const appConditions = [
    { condition: !userCredentials, redirectTo: '/login' },
    { condition: !!(userCredentials && (!user || !user.email || !user.activeGroupId)), redirectTo: '/onboard' },
  ];

  const renderPluginRoutes = () => plugins
    .flatMap((plugin) => Object.entries(plugin.routes).map((e) => [plugin.id, ...e]))
    .map(([pluginId, path, Component]) => [`/plugins/${pluginId}${path}`, Component])
    .map(([path, Component]) => (
      <ConditionalRoute
        key={path}
        path={path}
        exact
        conditions={appConditions}
        component={() => (
          <PluginContainer><Component /></PluginContainer>
        )}
      />
    ));

  useEffect(() => {
    dispatch(setAppBusy(user && !pluginsInitialised));
  }, [pluginsInitialised]);

  return (
    <BaseApp>
      <PluginsContextProvider plugins={plugins}>
        <Switch>
          <ConditionalRoute path="/onboard" conditions={onboardConditions} component={OnboardContainer} exact />
          <ConditionalRoute path="/register" conditions={loginConditions} component={RegistrationContainer} exact />
          <ConditionalRoute path="/login" conditions={loginConditions} component={LoginContainer} exact />
          <ConditionalRoute path="/forgotten_password" conditions={loginConditions} component={ForgottenPasswordContainer} exact />
          <ConditionalRoute path="/" conditions={appConditions} component={HomeContainer} exact />
          <ConditionalRoute path="/workers" conditions={appConditions} component={WorkersContainer} exact />
          <ConditionalRoute path="/workers/:trackableId/edit" conditions={appConditions} component={WorkerEditContainer} exact />
          <ConditionalRoute path="/workers/add" conditions={appConditions} component={WorkerEditContainer} exact />
          <ConditionalRoute path="/workers/locations" conditions={appConditions} component={WorkerLocationsContainer} exact />
          <ConditionalRoute path="/users" conditions={appConditions} component={UsersContainer} exact />
          <ConditionalRoute path="/users/:email/edit" conditions={appConditions} component={UserEditContainer} exact />
          <ConditionalRoute path="/users/add" conditions={appConditions} component={UserEditContainer} exact />
          <ConditionalRoute path="/notifications" conditions={appConditions} component={NotificationsContainer} exact />
          <ConditionalRoute path="/notifications/:subscriptionId/edit" conditions={appConditions} component={NotificationEditContainer} exact />
          <ConditionalRoute path="/notifications/add" conditions={appConditions} component={NotificationEditContainer} exact />
          <ConditionalRoute path="/messages" conditions={appConditions} component={MessagesContainer} exact />
          <ConditionalRoute path="/events" conditions={appConditions} component={EventsContainer} exact />
          <ConditionalRoute path="/calendar" conditions={appConditions} component={CalendarContainer} exact />
          {renderPluginRoutes()}
        </Switch>
      </PluginsContextProvider>
    </BaseApp>
  );
};

export default App;
