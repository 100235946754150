import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const EventsFilterView = ({
  open,
  filters,
  appIds,
  originatorIds,
  onDismiss,
  onSubmit,
}) => {
  const [value, setValue] = useState({
    from: filters.from || null,
    to: filters.to || null,
    appIds: filters.workflowAppId || '',
    originatorIds: filters.originator || '',
  });

  useEffect(() => {
    setValue({
      from: filters.from || null,
      to: filters.to || null,
      appIds: filters.workflowAppId || '',
      originatorIds: filters.originator || '',
    });
  }, [filters]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(value);
  };

  const originatorsItems = () => originatorIds.map((id, index) => (index === 0 ? (
    <MenuItem key="none" value="">
      <em>- none -</em>
    </MenuItem>
  ) : (
    <MenuItem key={id} value={id}>
      {id}
    </MenuItem>
  )));

  const appItems = () => appIds.map((id, index) => (index === 0 ? (
    <MenuItem key="none" value="">
      <em>- none -</em>
    </MenuItem>
  ) : (
    <MenuItem key={id} value={id}>
      {id}
    </MenuItem>
  )));

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              ampm={false}
              clearable
              fullWidth
              value={value.from}
              name="from"
              label="From"
              onChange={(d) => setValue({ ...value, from: d })}
            />
            <DateTimePicker
              ampm={false}
              clearable
              fullWidth
              value={value.to}
              name="to"
              label="To"
              onChange={(d) => setValue({ ...value, to: d })}
            />
          </MuiPickersUtilsProvider>
          <FormControl fullWidth>
            <InputLabel htmlFor="workflowAppIds">Id</InputLabel>
            <Select
              name="workflowAppIds"
              onChange={(e) => setValue({
                ...value,
                appIds: e.target.value,
              })}
              value={value.appIds}
            >
              {appItems()}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="originator">Originator</InputLabel>
            <Select
              name="originator"
              onChange={(e) => {
                setValue({
                  ...value,
                  originatorIds: e.target.value,
                });
              }}
              value={value.originatorIds}
            >
              {originatorsItems()}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onDismiss}>
            Cancel
          </Button>
          <Button type="submit">Filter</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

EventsFilterView.propTypes = {
  open: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    originator: PropTypes.arrayOf(PropTypes.string),
    workflowAppId: PropTypes.arrayOf(PropTypes.string),
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    limit: PropTypes.number,
  }).isRequired,
  appIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  originatorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

EventsFilterView.displayName = 'EventsFilterView';

export default EventsFilterView;
