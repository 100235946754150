import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEvents } from 'store/actions';

export default ({
  from, to, originator, workflowAppId,
} = {}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const events = useSelector((state) => state.events)
    // applying filters anyway as the events already in the store may
    // belong to previous filters
    .filter(
      (ev) => (to ? ev.timestamp * 1000 <= to.getTime() : true)
        && (from ? parseInt(ev.timestamp, 10) * 1000 >= from.getTime() : true)
        && (originator ? originator.includes(ev.originator) : true)
        && (workflowAppId ? workflowAppId.includes(ev.workflowAppId) : true),
    );

  useEffect(() => {
    dispatch(
      fetchEvents({
        from: from && Math.floor(from.getTime() / 1000),
        to: to && Math.floor(to.getTime() / 1000),
        originator,
        workflowAppId,
        groupId: user.activeGroupId,
      }),
    );
  }, [from, to, originator, workflowAppId]);

  return events;
};
